import "./App.css";
import "leaflet/dist/leaflet.css";
import React, { useEffect, useState, useRef } from "react";
import { Nav, Navbar, Offcanvas } from 'react-bootstrap';
import L, { LatLng, Icon, Map, LatLngExpression, Polyline } from "leaflet";
import "react-datepicker/dist/react-datepicker.css";
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { Logout, calculateVoltage, getAllDevicesDate, getLastestData, getData, DateFormatWithSlash, FormatStartDate, FormatEndDate, moveTo, addUsageRecord } from './ELTRES_cmnFunc';
import { DeviceData, Data, UsageRecord } from "./ELTRES_cmnDefine";
import { Collapse } from "react-bootstrap";
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { add } from "date-fns";

//--------------Data from DB-------------------
type Pins = { position: LatLng, name: string, devId: number, dateTime: string, adc: string, rssi: number, visible: boolean, color: string, initialColor: string }[];
const initialPins: { position: LatLng, name: string, devId: number, dateTime: string, adc: string, rssi: number, visible: boolean, color: string, initialColor: string }[] = [];

function createIcon(color: string) {
    return new Icon({
        iconUrl: require(`./img/${color}.png`),
        iconSize: [30, 30],
        iconAnchor: [15, 28],
        popupAnchor: [0, -30],
    });
}

function assignColorsToDevices(pins: Pins, availableColors: string[]) {
    const devIdToColorMap: { [devId: number]: string } = {};

    const updatedPins = pins.map((pin, index) => {
        let color: string;

        // 如果设备已经分配了颜色，则使用相同的颜色
        if (devIdToColorMap[pin.devId]) {
            color = devIdToColorMap[pin.devId];
        } else {
            // 如果设备没有分配颜色，则根据index分配颜色
            color = availableColors[index % availableColors.length];
            devIdToColorMap[pin.devId] = color; // 保存颜色映射关系
        }

        return { ...pin, color, initialColor: color };
    });

    return { updatedPins, devIdToColorMap };
}

library.add(faMapMarkerAlt);


export function MovingHistory(): JSX.Element {
    const uId = sessionStorage.getItem('u_id')?.toString();
    const authority = sessionStorage.getItem('authority')?.toString();
    const cIDSess = sessionStorage.getItem('client_id')?.toString();
    const client_id = cIDSess && !isNaN(parseInt(cIDSess)) ? parseInt(cIDSess) : null;
    const u_id = uId ? JSON.parse(uId).u_id : 0;
    const savedLayer = localStorage.getItem('selectedLayer');
    const mapRef = React.createRef<HTMLDivElement>();
    const [map, setMap] = useState<Map | null>(null);
    const [layer, setLayer] = useState(savedLayer || '');
    const [pins, setPins] = useState(initialPins);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [selectedDevIds, setSelectedDevIds] = useState<number[]>([]);
    const currentDate = new Date();
    const startTime = new Date(currentDate);
    const [startDate, setStartDate] = useState(FormatStartDate(currentDate));
    const [endDate, setEndDate] = useState(FormatEndDate(currentDate));
    startTime.setHours(currentDate.getHours() - 3)
    const allColors: string[] = ['red', 'blue', 'green', 'orange', 'purple', 'pink', 'blueViolet', 'yellow', 'lightBlue', 'lightGreen'];
    const { updatedPins, devIdToColorMap } = assignColorsToDevices(pins, allColors);
    const [initialDevIdToColorMap, setInitialDevIdToColorMap] = useState<{ [key: number]: string }>({});
    const [polylines, setPolylines] = useState<Polyline[]>([]);
    const devIdToLatLngs: Record<number, LatLngExpression[]> = {};
    const [allDevicesData, setAllDevicesData] = useState<DeviceData[]>([]);
    const [deviceDataAvailability, setDeviceDataAvailability] = useState<Record<number, boolean>>({});
    const showAdminLinks = authority !== '2';
    const isClientAdmin = authority == '1';
    const uniquePins: Pins = [];
    const [markerRefs, setMarkerRefs] = useState<globalThis.Map<number, L.Marker[]>>(new globalThis.Map());
    const [polylineRefs, setPolylineRefs] = useState<globalThis.Map<number, L.Polyline[]>>(new globalThis.Map());
    const isFirstRender = useRef(true);
    let [availableColors, setAvailableColors] = useState<string[]>([]);

    function calculateVisibleTiles(map: L.Map, client_id: number | null, uId: number) {
        const bounds = map.getBounds();
        const tileSize = 256;
        const nw = bounds.getNorthWest();
        const se = bounds.getSouthEast();

        const nwTile = map.project(nw, map.getZoom()).divideBy(tileSize).floor();
        const seTile = map.project(se, map.getZoom()).divideBy(tileSize).floor();

        const visibleTilesCount = (seTile.x - nwTile.x + 1) * (seTile.y - nwTile.y + 1);
        // console.log('Visible Tiles Count:', visibleTilesCount);
        localStorage.setItem('visibleTilesCount', JSON.stringify(visibleTilesCount));

        const usage: UsageRecord = {
            use_date: new Date().toISOString(),
            client_id: client_id,
            u_id: uId,
            event_type: 0,
            tile_count: visibleTilesCount
        };
        addUsageRecord(usage);
    };

    const fetchData = async () => {
        try {
            let devices = await getAllDevicesDate();
            if (devices) {
                devices = devices.filter((d: any) => d.enable === true);
                if (isClientAdmin && cIDSess) {
                    devices = devices.filter((d: any) => d.client_id == parseInt(cIDSess));
                }

                let dataAvailability: Record<number, boolean> = {};

                const latestDataPromises = devices.map(async (device: DeviceData) => {
                    const latestData = await getLastestData(device.dev_id);
                    if (latestData !== null &&
                        latestData.latitude <= 90 && latestData.latitude >= -90 &&
                        latestData.longitude <= 180 && latestData.longitude >= -180
                    ) {
                        dataAvailability[device.dev_id] = true;
                        return latestData;
                    } else {
                        dataAvailability[device.dev_id] = false;
                        return null;
                    }
                });
                const latestDataResults = (await Promise.all(latestDataPromises)).filter(Boolean); //filter null

                const newPins = latestDataResults.map(data => ({
                    position: new LatLng(parseFloat(data.latitude.toFixed(6)), parseFloat(data.longitude.toFixed(6))),
                    name: devices.find((device: DeviceData) => device.dev_id === data.dev_id)?.dev_name || 'Unknown',
                    devId: data.dev_id,
                    dateTime: DateFormatWithSlash(new Date(data.tx_time)),
                    adc: calculateVoltage(data.src_02),
                    rssi: data.rssi,
                    visible: true,
                    initialColor: '',
                    color: ''
                }));
                const initialDevIds = devices.slice(0, 10).map((device: DeviceData) => device.dev_id);
                setSelectedDevIds(initialDevIds);
                setDeviceDataAvailability(dataAvailability);
                setAllDevicesData(devices);
                const { updatedPins, devIdToColorMap } = assignColorsToDevices(newPins, allColors);
                setPins(updatedPins);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        //---------------gruoupingPins----------------------
        pins.forEach((pin) => {
            const existingPin = uniquePins.find((item) => item.devId === pin.devId);
            if (!existingPin) {
                uniquePins.push(pin);
            }
        });

        const initialDevIds = uniquePins.slice(0, allDevicesData.length).map((pin) => pin.devId);
        initialDevIds.forEach((devId, index) => {
            devIdToColorMap[devId] = allColors[index];
        });

        pins.forEach((pin) => {
            if (!pin.color) {
                pin.color = devIdToColorMap[pin.devId];
                pin.initialColor = pin.color;
            }
            if (!devIdToLatLngs[pin.devId]) {
                devIdToLatLngs[pin.devId] = [];
            }
            devIdToLatLngs[pin.devId].push(pin.position);
        });
    })

    //---------------render page-------------------
    useEffect(() => {
        fetchData();
    }, [])

    useEffect(() => {
        const initialMap: { [key: number]: string } = {};
        const allDevId = allDevicesData.map((device) => device.dev_id);
        for (let i = 0; i < allDevId.length; i++) {
            initialMap[allDevId[i]] = allColors[i % allColors.length];
        }
        setInitialDevIdToColorMap(initialMap);
    }, [allDevicesData]);

    //---------------render page-------------------
    useEffect(() => {
        if (isFirstRender.current) {
            const visibleTilesCount = localStorage.getItem('visibleTilesCount');
            const usage: UsageRecord = {
                use_date: new Date().toISOString(),
                client_id: client_id,
                u_id: u_id,
                event_type: 0,
                tile_count: visibleTilesCount ? parseInt(visibleTilesCount) : 0
            };
            addUsageRecord(usage);
            isFirstRender.current = false;
            return;
        }

        if (mapRef.current) {
            const initialMap = L.map(mapRef.current);
            const storedZoom = localStorage.getItem('mapZoom');
            const storedCenterStr = localStorage.getItem('mapCenter');
            const storedCenter = storedCenterStr ? JSON.parse(storedCenterStr) : null;

            if (storedZoom && storedCenter) {
                initialMap.setView(storedCenter, parseInt(storedZoom));
            } else {
                initialMap.setView([34.3852, 132.4552], 15);
            }

            initialMap.on('zoomend', () => {
                const currentZoom = initialMap.getZoom();
                localStorage.setItem('mapZoom', currentZoom.toString());
            });

            initialMap.on('moveend', () => {
                const currentCenter = initialMap.getCenter();
                calculateVisibleTiles(initialMap, client_id, u_id)
                localStorage.setItem('mapCenter', JSON.stringify([currentCenter.lat, currentCenter.lng]));
            });

            setMap(initialMap);

            //-----------prepare baselayers--------------
            const mapLayer = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                attribution: "Map data © <a href='https://openstreetmap.org'>OpenStreetMap</a> contributors"
            });

            const satelliteLayer = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
                attribution: "Map data © <a href='https://openstreetmap.org'>OpenStreetMap</a> contributors"
            });

            if (layer === '地図') {
                mapLayer.addTo(initialMap);
            } else {
                satelliteLayer.addTo(initialMap);
            }

            const layerControl = L.control.layers({
                '地図': mapLayer,
                '衛星写真': satelliteLayer
            }).addTo(initialMap);

            initialMap.on('baselayerchange', (event) => {
                setLayer(event.name);
            });


            const markers: L.Marker[] = [];
            pins.forEach((pin) => {
                if (pin.visible) {
                    const marker = L.marker(pin.position, {
                        icon: createIcon(pin.color),
                        opacity: selectedDevIds.includes(pin.devId) ? 1 : 0
                    })
                        .addTo(initialMap)
                        .bindPopup(`デバイス：${pin.name} <br /> 日付：${pin.dateTime} <br /> 緯度：${pin.position.lat} <br /> 経度：${pin.position.lng} <br /> 電池残量：${pin.adc} <br /> RSSI：${pin.rssi}`);

                    setMarkerRefs(prevRefs => {
                        const updatedRefs = new globalThis.Map(prevRefs);
                        const markers = updatedRefs.get(pin.devId) || [];
                        markers.push(marker);
                        updatedRefs.set(pin.devId, markers);
                        return updatedRefs;
                    });
                } else {
                    map?.eachLayer((layer) => {
                        if (layer instanceof L.Marker && layer.getLatLng().equals(pin.position)) {
                            map.removeLayer(layer);
                        }
                    })
                }
            });

            const newPolylines: Polyline[] = [];
            Object.keys(devIdToLatLngs).forEach((devId) => {
                const pin = pins.find(pin => pin.devId == parseInt(devId))
                if (pin?.visible) {
                    const latlngs = devIdToLatLngs[parseInt(devId)];
                    if (latlngs.length > 1) {
                        const polylineColor = pin.color;
                        const polyline = L.polyline(latlngs, { color: polylineColor }).addTo(initialMap);
                        setPolylineRefs(prevRefs => {
                            const updatedRefs = new globalThis.Map(prevRefs);
                            const polylines = updatedRefs.get(pin.devId) || [];
                            polylines.push(polyline);
                            updatedRefs.set(pin.devId, polylines);
                            return updatedRefs;
                        });
                    }
                }
            });
            setPolylines(newPolylines);

            return () => {
                initialMap.remove();
                markers.forEach(marker => marker.remove());
            };
        }
    }, [pins]);

    useEffect(() => {
        localStorage.setItem('selectedLayer', layer);
    }, [layer]);

    //--------------ViewAllPins-------------------
    const handleViewAllClick = () => {
        if (map && pins.length !== 0) {
            const bounds = L.latLngBounds(pins.map(pin => pin.position));
            map.fitBounds(bounds);
        }
    };

    //--------------HandleVisibility-------------------
    const handleCheckboxChange = (devId: number, devColor: string, initialColor: string) => {
        if (selectedDevIds.includes(devId)) {
            const updatedDevIds = selectedDevIds.filter(id => id !== devId);
            setSelectedDevIds(updatedDevIds);
            const markers = markerRefs.get(devId);
            const polylines = polylineRefs.get(devId);
            if (markers) {
                markers.forEach(marker => marker.setOpacity(0));
            }
            if (polylines) {
                polylines.forEach(polyline => polyline.setStyle({ opacity: 0 }));
            }
            availableColors.push(devColor)
            console.log(availableColors)

        } else {
            if (selectedDevIds.length < 10) {
                setSelectedDevIds([...selectedDevIds, devId]);
                const markers = markerRefs.get(devId);
                const polylines = polylineRefs.get(devId);
                let setNewColor = availableColors.includes(initialColor) ? initialColor : availableColors[0];
                const updatedIcon = createIcon(setNewColor)

                if (markers) {
                    markers.forEach(marker => {
                        marker.setIcon(updatedIcon);
                        marker.setOpacity(1);
                    });
                }
                if (polylines) {
                    polylines.forEach(polyline => {
                        polyline.setStyle({ opacity: 1, color: setNewColor });
                    });
                }
                availableColors = availableColors.filter(color => color !== setNewColor)
                setAvailableColors(availableColors)

            } else {
                alert('デバイスは最大で10個しか選択できません。');
            }
        }
    }

    //----------------SearchClick-------------------

    const fetchDataForTimeRange = async (startDate: string, endDate: string, selectedDevIds: number[]) => {
        let allPins: Pins = [];
        let dataAvailability: Record<number, boolean> = { ...deviceDataAvailability };
        let availableColors = allColors;
        let occupiedColors = [];

        for (const devId of selectedDevIds) {
            try {
                const datas = await getData(devId, startDate, endDate)
                if (datas && datas.length > 0) {
                    let color: string = '';
                    if (availableColors.includes(initialDevIdToColorMap[devId])) {
                        color = initialDevIdToColorMap[devId];
                        availableColors = availableColors.filter(c => c !== color);
                        occupiedColors.push(color);
                    } else {
                        color = availableColors[0];
                        availableColors = availableColors.slice(1);
                        occupiedColors.push(color);
                    }
                    const validPinsForDevice = datas.filter((d: Data) => {
                        return d.latitude <= 90 && d.latitude >= -90 && d.longitude <= 180 && d.longitude >= -180;
                    }).map((d: Data) => ({
                        position: new LatLng(d.latitude, d.longitude),
                        name: allDevicesData.find((device: DeviceData) => device.dev_id === d.dev_id)?.dev_name || 'Unknown',
                        devId: d.dev_id,
                        dateTime: DateFormatWithSlash(new Date(d.tx_time)),
                        adc: d.src_02 ? calculateVoltage(d.src_02) : '',
                        rssi: d.rssi,
                        visible: true,
                        color: color !== '' ? color : initialDevIdToColorMap[devId],
                        initialColor: initialDevIdToColorMap[devId]
                    }));

                    allPins = [...allPins, ...validPinsForDevice];
                    dataAvailability[devId] = true;
                } else {
                    dataAvailability[devId] = false;
                }
            } catch (error) {
                console.error(`Error fetching data for device ${devId}:`, error);
            }
        }
        setDeviceDataAvailability(dataAvailability);
        return allPins;
    }

    const handleSearchClick = async () => {
        if (startDate > endDate) {
            return alert('終了日は開始日より後にしてください。');
        }
        const formatStartDate = startDate.toISOString();
        const formatEndDate = endDate.toISOString();
        polylines.forEach(polyline => polyline.remove());
        setPolylines([]);
        const newPins = await fetchDataForTimeRange(formatStartDate, formatEndDate, selectedDevIds);
        const usage: UsageRecord = {
            use_date: new Date().toISOString(),
            client_id: cIDSess != null ? parseInt(cIDSess) : 0,
            u_id: uId ? JSON.parse(uId).u_id : 0,
            event_type: 2,
            tile_count: 0
        };
        await addUsageRecord(usage);

        const updatedPins = newPins.map((pin) => {
            pin.color = devIdToColorMap[pin.devId];
            pin.initialColor = pin.color;
            return pin;
        });

        setPins(updatedPins);
        setIsDropdownOpen(false);
    };

    //----------------reset setting-------------------
    const reset = () => {
        fetchData();
        setStartDate(FormatStartDate(currentDate));
        setEndDate(FormatEndDate(currentDate));
        setIsDropdownOpen(false);
    };


    return (
        <div className="App">
            <Navbar collapseOnSelect expand="lg" className="navbar">
                <Navbar.Brand href="/eltres/tracking">ElTRES</Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Offcanvas placement="end" style={{ width: "200px" }}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>
                            ElTRES
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Nav className="justify-content-end flex-grow-1 pe-3 nav-tab">
                            <Nav.Link href="/eltres/tracking">現在地</Nav.Link>
                            <Nav.Link href="/eltres/movingHistory" style={{ color: '#bbbcbd' }}>移動履歴</Nav.Link>
                            {showAdminLinks && (
                                <>
                                    <Nav.Link href="/eltres/clients" hidden={isClientAdmin}>取引先設定</Nav.Link>
                                    <Nav.Link href="/eltres/users">ユーザ設定</Nav.Link>
                                    <Nav.Link href="/eltres/devices">端末設定</Nav.Link>
                                    <Nav.Link href="/eltres/payloads" hidden={isClientAdmin}>ペイロード設定</Nav.Link>
                                    <Nav.Link href="/eltres/usageAmount" hidden={isClientAdmin}>使用量</Nav.Link>
                                </>
                            )}
                            <Nav.Link href="/eltres/download">ダウンロード</Nav.Link>
                            <hr />
                            <Nav.Link href="/eltres/changePWD">パスワード変更</Nav.Link>
                            <Nav.Link onClick={() => uId ? Logout(uId) : moveTo('/eltres/login')}>ログアウト</Nav.Link>
                        </Nav>
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
            </Navbar>

            <div className="map-container" ref={mapRef} style={{ position: 'relative', marginTop: '0px', width: '100%', height: '100vh' }}></div>

            <div className="pin-list">
                <button className="btn btn-viewAll" onClick={handleViewAllClick}>全面見る</button>
                <div className="custom-dropdown">
                    <button className="btn btn-dropdown" onClick={() => setIsDropdownOpen(!isDropdownOpen)} aria-controls="custom-dropdown-content" aria-expanded={isDropdownOpen}>デバイス</button>
                    <Collapse in={isDropdownOpen}>
                        <div className="custom-dropdown-content" id="custom-dropdown-content">
                            <button className="btn btn-search" onClick={() => handleSearchClick()}>検索</button>
                            <button className="btn btn-reset" onClick={reset}>リセット</button>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DateTimePicker
                                    label="Start"
                                    defaultValue={startDate}
                                    value={startDate}
                                    onChange={(newValue) => {
                                        if (newValue) {
                                            setStartDate(newValue);
                                        }
                                    }}
                                    slotProps={{
                                        actionBar: {
                                            actions: ['cancel', 'accept'],
                                        },
                                    }}
                                    maxDate={endDate}
                                    format="yyyy/MM/dd HH:mm"
                                    closeOnSelect={false}
                                />
                                <DateTimePicker
                                    label="End"
                                    value={endDate}
                                    defaultValue={endDate}
                                    onChange={(newValue) => {
                                        if (newValue) {
                                            setEndDate(newValue);
                                        }
                                    }}
                                    slotProps={{
                                        actionBar: {
                                            actions: ['cancel', 'accept'],
                                        },
                                    }}
                                    minDate={startDate}
                                    maxDate={new Date()}
                                    format="yyyy/MM/dd HH:mm"
                                    closeOnSelect={false}
                                />
                            </LocalizationProvider>
                            <p></p>
                            {allDevicesData.length > 0 ? (allDevicesData.map((device) => (
                                <div key={device.dev_id} style={{ cursor: 'pointer', marginTop: '3px' }}>
                                    <input
                                        type="checkbox"
                                        id={`checkbox-${device.dev_id}`}
                                        className="checkbox-item"
                                        checked={selectedDevIds.includes(device.dev_id)}
                                        onChange={() => handleCheckboxChange(device.dev_id, pins.find(pin => pin.devId === device.dev_id)?.color || 'default', pins.find(pin => pin.devId === device.dev_id)?.initialColor || 'default')}
                                    />
                                    {device.dev_name}
                                    {!deviceDataAvailability[device.dev_id] && <span style={{ color: 'red' }}>　✕</span>}
                                </div >
                            ))
                            ) : (
                                <div style={{ color: 'red' }}>ユーザーに紐づけてる装置の情報がありません。</div>
                            )
                            }
                        </div>
                    </Collapse>
                </div>
            </div>
        </div>
    );
}

export default MovingHistory;