import "./App.css";
import "leaflet/dist/leaflet.css";
import React, { useEffect, useRef, useState } from "react";
import { Nav, Navbar, Offcanvas, Modal, Button } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";
import { autoLogoutAlert, Logout, getAllUsersData, DateFormatWithSlash, DateFormatOnlyDate, FormatStartDateByYear, FormatEndDate, moveTo, getAllClientsDate, getUsage, getUsageCount, downloadCSV } from './ELTRES_cmnFunc';
import { UserData, FullUserData, ClientData, EventDataForClient, UsageRecord, EventDataForUser } from "./ELTRES_cmnDefine";
import DatePicker from 'react-datepicker';

export function UsageAmount(): JSX.Element {
    const uId = sessionStorage.getItem('u_id')?.toString();
    const currentDate = new Date();
    const [startDate, setStartDate] = useState(FormatStartDateByYear(currentDate));
    const [endDate, setEndDate] = useState(FormatEndDate(currentDate));
    const [allUsersData, setAllUsersData] = useState<UserData[]>([]);
    const [fullUserData, setFullUserData] = useState<FullUserData[]>([]);
    const [allClientData, setAllClientData] = useState<ClientData[]>([]);
    const [allEventDataForClient, setAllEventDataForClient] = useState<EventDataForClient[]>([]);
    const [selectedEventData, setSelectedEventData] = useState<EventDataForClient>();
    const [allEventDataForUser, setAllEventDataForUser] = useState<EventDataForUser[]>([]);
    const [showModal, setShowModal] = useState(false);


    //------------------renden page--------------------
    useEffect(() => {
        if (allUsersData.length == 0) {
            getAllUsersData()
                .then((data) => {
                    setAllUsersData(data);
                    // console.log('allUsersData: ' + allUsersData)
                })
                .catch((error) => {
                    autoLogoutAlert()
                    console.error('Failed to getAllUsersDate. Occured : ' + error);
                })
        }
        if (allClientData.length == 0) {
            getAllClientsDate()
                .then((data) => {
                    const vaildData = data.filter((client: any) => client.enable !== false);
                    setAllClientData(vaildData);
                })
                .catch((error) => {
                    autoLogoutAlert()
                    console.error('Failed to getAllClientsDate. Occured : ' + error);
                })
        }
    }, [])

    useEffect(() => {
        try {
            const fetchFullUserData = async () => {
                const promises = allUsersData.map(async (user) => {
                    // console.log('user.u_id : '+ user.u_id)
                    const response = await fetch(`/api/v1/user/${user.u_id}`);
                    const userData = await response.json();
                    const fullUserData: FullUserData = {
                        ...user,
                        password: userData.password ? userData.password : "",
                        client_id: userData.client_id ? userData.client_id : null,
                        name_knj: userData.name_knj,
                        name_kana: userData.name_kana,
                        enable: userData.enable,
                        remark: userData.remark,
                    };
                    return fullUserData;
                });

                const fullUserDataArray = await Promise.all(promises);
                setFullUserData(fullUserDataArray);
                // console.log(fullUserDataArray);
            };

            const fetchEventData = async () => {
                const promises = allClientData.map(async (client) => {
                    const data = await getUsageCount(client.client_id, startDate.toISOString(), endDate.toISOString())
                    if (data === null) {
                        return {
                            client_id: client.client_id,
                            map_count: 0,
                            total_tile_count: 0,
                            search_count: 0,
                            download_count: 0
                        }
                    } else {
                        const eventData: EventDataForClient = {
                            client_id: client.client_id,
                            map_count: data.map_count,
                            total_tile_count: data.total_tile_count,
                            search_count: data.search_count,
                            download_count: data.download_count
                        }
                        return eventData;
                    }
                });

                const data = await getUsageCount(0, startDate.toISOString(), endDate.toISOString()) // to get data for client_id = null
                const eventDataArray = await Promise.all(promises);
                let filteredEventDataArray = eventDataArray.filter((eventData) => eventData !== undefined) as EventDataForClient[];
                if (data !== null) {
                    const eventData: EventDataForClient = {
                        client_id: 0,
                        map_count: data.map_count,
                        total_tile_count: data.total_tile_count,
                        search_count: data.search_count,
                        download_count: data.download_count
                    }
                    filteredEventDataArray.push(eventData);
                }
                setAllEventDataForClient(filteredEventDataArray);
            };


            if (allUsersData.length > 0) {
                fetchFullUserData();
            }

            if (allClientData.length > 0) {
                fetchEventData();
            }

        } catch (error) {
            autoLogoutAlert();
            console.error("Failed to fetch allUsersData. Occured : " + error)
        }
    }, [allClientData, allUsersData]);

    //----------------SearchClick-------------------

    const handleSearchClick = async () => {
        if (startDate > endDate) {
            return alert('終了日は開始日より後にしてください。');
        }
        const formatStartDate = startDate.toISOString();
        const formatEndDate = endDate.toISOString();

        const promises = allClientData.map(async (client) => {
            const data = await getUsageCount(client.client_id, formatStartDate, formatEndDate)
            if (data === null) {
                return {
                    client_id: client.client_id,
                    map_count: 0,
                    total_tile_count: 0,
                    search_count: 0,
                    download_count: 0
                }
            } else {
                const eventData: EventDataForClient = {
                    client_id: client.client_id,
                    map_count: data.map_count,
                    total_tile_count: data.total_tile_count,
                    search_count: data.search_count,
                    download_count: data.download_count
                }
                return eventData;
            }
        });
        const data = await getUsageCount(0, formatStartDate, formatEndDate) // to get data for client_id = null

        const eventDataArray = await Promise.all(promises);
        let filteredEventDataArray = eventDataArray.filter((eventData) => eventData !== undefined) as EventDataForClient[];
        if (data !== null) {
            const eventData: EventDataForClient = {
                client_id: 0,
                map_count: data.map_count,
                total_tile_count: data.total_tile_count,
                search_count: data.search_count,
                download_count: data.download_count
            }
            filteredEventDataArray.push(eventData);
        }
        setAllEventDataForClient(filteredEventDataArray);
    };

    //----------------DownloadClick-------------------

    async function handleDownloadClick(client_id: number, client_name: string) {
        let confirmmsg = '';
        let fileName = '';

        const formatStartWS = DateFormatWithSlash(startDate)
        const formatEndWS = DateFormatWithSlash(endDate)
        confirmmsg = formatStartWS + '～' + formatEndWS + '期間\n' + client_name

        const formatStart = DateFormatOnlyDate(startDate)
        const formatEnd = DateFormatOnlyDate(endDate)
        fileName = formatStart + '_' + formatEnd + '_' + client_name;
        if (client_name != null && window.confirm(confirmmsg + ' の使用データをダウンロードします。\nよろしいですか？')) {
            try {
                const dataResponse = await getUsage(client_id, startDate.toISOString(), endDate.toISOString());
                if (dataResponse) {
                    const formattedData = dataResponse.map((data: UsageRecord) => {
                        return {
                            ...data,
                            use_date: DateFormatWithSlash(new Date(data.use_date)),
                        };
                    });

                    if (formattedData.length > 0) {
                        downloadCSV(fileName, formattedData)
                    } else {
                        alert("検索範囲内のデータ情報がありません。")
                    }
                } else {
                    console.error('Failed to handle downloadClick. Occured : ' + dataResponse.status);
                }
            } catch (error) {
                console.error('Failed to handle downloadClick. Occured : ' + error);
                autoLogoutAlert();
            }
        }

    }

    async function handleDetailsClick(selectedDate: EventDataForClient) {
        setShowModal(true);
        setSelectedEventData(selectedDate);
        try {
            const dataResponse = await getUsage(selectedDate.client_id, startDate.toISOString(), endDate.toISOString());
            if (dataResponse) {
                const usageRecords: UsageRecord[] = dataResponse;
                const userUsageMap = new Map<number, UsageRecord[]>(); // key: u_id, value: UsageRecord[]
                const eventDataForUser: EventDataForUser[] = [];

                usageRecords.forEach((record) => {
                    const { u_id } = record;
                    if (!userUsageMap.has(u_id)) {
                        userUsageMap.set(u_id, []);
                    }
                    userUsageMap.get(u_id)?.push(record);
                });

                userUsageMap.forEach((records, u_id) => {
                    // 0: map, 1: download, 2: search
                    const mapCount = records.filter((r) => r.event_type === 0).length;
                    const downloadCount = records.filter((r) => r.event_type === 1).length;
                    const searchCount = records.filter((r) => r.event_type === 2).length;
                    const totalTileCount = records.reduce((sum, r) => sum + (r.tile_count || 0), 0);

                    const eventData: EventDataForUser = {
                        u_id: u_id,
                        map_count: mapCount,
                        download_count: downloadCount,
                        search_count: searchCount,
                        total_tile_count: totalTileCount,
                    };

                    eventDataForUser.push(eventData);
                });

                setAllEventDataForUser(eventDataForUser);

            } else {
                console.error('Failed to handle downloadClick. Occured : ' + dataResponse.status);
            }
        } catch (error) {
            console.error('Failed to handle downloadClick. Occured : ' + error);
            autoLogoutAlert();
        }
    }

    return (
        <div className="App">
            <Navbar collapseOnSelect expand="lg" className="navbar">
                <Navbar.Brand href="/eltres/tracking">ElTRES</Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Offcanvas placement="end" style={{ width: "200px" }}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>
                            ElTRES
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Nav className="justify-content-end flex-grow-1 pe-3 nav-tab">
                            <Nav.Link href="/eltres/tracking">現在地</Nav.Link>
                            <Nav.Link href="/eltres/movingHistory">移動履歴</Nav.Link>
                            <Nav.Link href="/eltres/clients">取引先設定</Nav.Link>
                            <Nav.Link href="/eltres/users">ユーザ設定</Nav.Link>
                            <Nav.Link href="/eltres/devices">端末設定</Nav.Link>
                            <Nav.Link href="/eltres/payloads">ペイロード設定</Nav.Link>
                            <Nav.Link href="/eltres/usageAmount" style={{ color: '#bbbcbd' }}>使用量</Nav.Link>
                            <Nav.Link href="/eltres/download">ダウンロード</Nav.Link>
                            <hr />
                            <Nav.Link href="/eltres/changePWD">パスワード変更</Nav.Link>
                            <Nav.Link onClick={() => uId ? Logout(uId) : moveTo('/eltres/login')}>ログアウト</Nav.Link>
                        </Nav>
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
            </Navbar>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <h2>使用量</h2>
                        <div style={{ marginLeft: '10px' }}>
                            <br />
                            <label className="dateLable" style={{ cursor: 'pointer', marginRight: '40px' }}>
                                期間：
                                <DatePicker
                                    dateFormat="yyyy/MM/dd HH:mm"
                                    selected={startDate}
                                    onChange={(date: Date) => {
                                        setStartDate(date);
                                    }}
                                    selectsStart
                                    startDate={startDate}
                                    endDate={endDate}
                                    maxDate={endDate}
                                    showTimeInput
                                    timeFormat="HH:mm"
                                    timeIntervals={1}
                                    timeCaption="時間"
                                />
                            </label>
                            ～
                            <label className="dateLable" style={{ cursor: 'pointer' }}>
                                <DatePicker
                                    className=""
                                    dateFormat="yyyy/MM/dd HH:mm"
                                    selected={endDate}
                                    onChange={(date: Date) => {
                                        const endDateWithTime = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999).getTime();
                                        setEndDate(new Date(endDateWithTime))
                                    }}
                                    selectsEnd
                                    startDate={startDate}
                                    endDate={endDate}
                                    minDate={startDate}
                                    showTimeInput
                                    timeFormat="HH:mm"
                                    timeIntervals={1}
                                    timeCaption="時間"
                                />
                            </label>

                            <button className='btn btn-primary' style={{ marginLeft: '50px' }} onClick={() => handleSearchClick()}>検索</button>
                        </div>
                    </div>
                </div>

                <div className="card shadow" style={{ marginTop: '20px' }}>
                    <div className="card-body p-4" >
                        <div className="table-card">
                            {allEventDataForClient.length > 0 ? (
                                <table className="table" style={{ textAlign: 'center' }}>
                                    <thead>
                                        <tr>
                                            <th className='widthNo'>No.</th>
                                            <th className='width15'>取引先名／取引先に紐づいでいない</th>
                                            <th className='width10'>マップの使用回数</th>
                                            <th className='width10'>マップのタイル枚数</th>
                                            <th className='width10'>地図検索回数</th>
                                            <th className='width10'>ダウンロード回数</th>
                                            <th className='width15'>詳細/ダウンロード</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {allEventDataForClient.map((event, index) => (
                                            <tr key={event.client_id}>
                                                <td>{index + 1}</td>
                                                <td style={{ padding: '10px' }}>{allClientData.find(client => client.client_id === event.client_id)?.name_knj || "システム管理者/一般使用者"}</td>
                                                <td style={{ padding: '10px' }}>{event.map_count}</td>
                                                <td style={{ padding: '10px' }}>{event.total_tile_count}</td>
                                                <td style={{ padding: '10px' }}>{event.search_count}</td>
                                                <td style={{ padding: '10px' }}>{event.download_count}</td>
                                                <td>
                                                    <button className='btn btn-secondary' style={{ marginRight: '10px' }}
                                                        disabled={event.total_tile_count == 0 && event.search_count == 0 && event.download_count == 0 ? true : false}
                                                        onClick={() => handleDetailsClick(event)}>
                                                        詳細
                                                    </button>
                                                    <button className='btn btn-warning'
                                                        disabled={event.total_tile_count == 0 && event.search_count == 0 && event.download_count == 0 ? true : false}
                                                        onClick={() => handleDownloadClick(event.client_id, allClientData.find(client => client.client_id === event.client_id)?.name_knj || "システム管理者/一般使用者")}>
                                                        ダウンロード
                                                    </button>
                                                </td>
                                            </tr>
                                        ))
                                        }
                                    </tbody>
                                </table>
                            ) : (
                                <div style={{ color: 'red' }}>使用量の情報がありません。</div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-container">
                <Modal show={showModal} onHide={() => setShowModal(false)} centered dialogClassName="modal-xl  modal-dialog-scrollable">
                    <Modal.Header closeButton>
                        <Modal.Title>詳細</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row usage-row">
                            <div className="col-md-12" style={{ padding: '0px' }}>
                                <div className="table-responsive">
                                    <table className="table" style={{ textAlign: 'center' }}>
                                        <thead>
                                            <tr>
                                                <th className='widthNo'>No.</th>
                                                <th className='width10'>ユーザー名</th>
                                                <th className='width10'>マップの使用回数</th>
                                                <th className='width10'>マップのタイル枚数</th>
                                                <th className='width10'>地図検索回数</th>
                                                <th className='width10'>ダウンロード回数</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {allEventDataForUser.map((event, index) => (
                                                <tr key={event.u_id}>
                                                    <td>{index + 1}</td>
                                                    <td style={{ padding: '10px' }}>{fullUserData.find(user => user.u_id === event.u_id)?.name_knj || ""}</td>
                                                    <td style={{ padding: '10px' }}>{event.map_count}</td>
                                                    <td style={{ padding: '10px' }}>{event.total_tile_count}</td>
                                                    <td style={{ padding: '10px' }}>{event.search_count}</td>
                                                    <td style={{ padding: '10px' }}>{event.download_count}</td>
                                                </tr>
                                            ))}
                                            <tr>
                                                <td style={{ padding: '10px', backgroundColor: '#f5f5f5' }}>合計</td>
                                                <td style={{ padding: '10px', backgroundColor: '#f5f5f5' }}></td>
                                                <td style={{ padding: '10px', backgroundColor: '#f5f5f5' }}>{selectedEventData?.map_count}</td>
                                                <td style={{ padding: '10px', backgroundColor: '#f5f5f5' }}>{selectedEventData?.total_tile_count}</td>
                                                <td style={{ padding: '10px', backgroundColor: '#f5f5f5' }}>{selectedEventData?.search_count}</td>
                                                <td style={{ padding: '10px', backgroundColor: '#f5f5f5' }}>{selectedEventData?.download_count}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowModal(false)}>閉じる</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    );
}

export default UsageAmount;