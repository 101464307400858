import "./App.css";
import "leaflet/dist/leaflet.css";
import React, { useEffect, useState, useRef } from "react";
import { Nav, Navbar, Offcanvas } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";
import { Logout, DateFormatWithSlash, DateFormatOnlyDate, FormatStartDate, FormatEndDate, getAllDevicesDate, getData, downloadCSV, autoLogoutAlert, moveTo, addUsageRecord } from './ELTRES_cmnFunc';
import { DeviceData, Data, UsageRecord } from "./ELTRES_cmnDefine";
import DatePicker from 'react-datepicker';


export function Download(): JSX.Element {
    const uId = sessionStorage.getItem('u_id')?.toString();
    const authority = sessionStorage.getItem('authority')?.toString();
    const cIDSess = sessionStorage.getItem('client_id')?.toString();
    const showAdminLinks = authority !== '2';
    const isClientAdmin = authority == '1';
    const currentDate = new Date();
    const [startDate, setStartDate] = useState(FormatStartDate(currentDate));
    const [endDate, setEndDate] = useState(FormatEndDate(currentDate));
    const [allDevicesData, setAllDevicesData] = useState<DeviceData[]>([]);
    const ref_dev = useRef<HTMLSelectElement>(null!);


    useEffect(() => {
        const fetchData = async () => {
            try {
                let devices = await getAllDevicesDate();
                if (devices) {
                    if (isClientAdmin && cIDSess) {
                        devices = devices.filter((d: any) => d.client_id == parseInt(cIDSess));
                    }
                    setAllDevicesData(devices);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [])

    //--------download CSV ---------
    async function handleDownloadClick() {
        let confirmmsg = '';
        let fileName = '';
        const devId = ref_dev.current.value;
        const selectedDevice = allDevicesData.find((device) => device.dev_id == parseInt(devId));

        if (selectedDevice) {
            const formatStartWS = DateFormatWithSlash(startDate)
            const formatEndWS = DateFormatWithSlash(endDate)
            const deviceName = selectedDevice.dev_name;
            confirmmsg = formatStartWS + '～' + formatEndWS + '期間\n' + deviceName

            const formatStart = DateFormatOnlyDate(startDate)
            const formatEnd = DateFormatOnlyDate(endDate)
            fileName = formatStart + '_' + formatEnd + '_' + deviceName;
            if (deviceName != null && window.confirm(confirmmsg + ' 装置のデータをダウンロードします。\nよろしいですか？')) {
                try {
                    const dataResponse = await getData(selectedDevice.dev_id, startDate.toISOString(), endDate.toISOString());
                    if (dataResponse) {
                        const formattedData = dataResponse.map((data: Data) => {
                            return {
                                ...data,
                                recv_date: DateFormatWithSlash(new Date(data.recv_date)),
                                tx_time: DateFormatWithSlash(new Date(data.tx_time)),
                            };
                        });

                        if (formattedData.length > 0) {
                            downloadCSV(fileName, formattedData)
                            const usage: UsageRecord = {
                                use_date: new Date().toISOString(),
                                client_id: cIDSess != null ? parseInt(cIDSess) : 0,
                                u_id: uId ? JSON.parse(uId).u_id : 0,
                                event_type: 1,
                                tile_count: 0
                            };
                            await addUsageRecord(usage);
                        } else {
                            alert("検索範囲内のデータ情報がありません。")
                        }
                    } else {
                        console.error('Failed to handle downloadClick. Occured : ' + dataResponse.status);
                    }
                } catch (error) {
                    console.error('Failed to handle downloadClick. Occured : ' + error);
                    autoLogoutAlert();
                }
            }
        } else {
            alert('装置を指定していないため、ダウンロードはできません。');
        }
    }



    return (
        <div className="App">
            <Navbar collapseOnSelect expand="lg" className="navbar">
                <Navbar.Brand href="/eltres/tracking">ElTRES</Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Offcanvas placement="end" style={{ width: "200px" }}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>
                            ElTRES
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Nav className="justify-content-end flex-grow-1 pe-3 nav-tab">
                            <Nav.Link href="/eltres/tracking">現在地</Nav.Link>
                            <Nav.Link href="/eltres/movingHistory">移動履歴</Nav.Link>
                            {showAdminLinks && (
                                <>
                                    <Nav.Link href="/eltres/clients" hidden={isClientAdmin}>取引先設定</Nav.Link>
                                    <Nav.Link href="/eltres/users">ユーザ設定</Nav.Link>
                                    <Nav.Link href="/eltres/devices">端末設定</Nav.Link>
                                    <Nav.Link href="/eltres/payloads" hidden={isClientAdmin}>ペイロード設定</Nav.Link>
                                    <Nav.Link href="/eltres/usageAmount" hidden={isClientAdmin}>使用量</Nav.Link>
                                </>
                            )}
                            <Nav.Link href="/eltres/download" style={{ color: '#bbbcbd' }}>ダウンロード</Nav.Link>
                            <hr />
                            <Nav.Link href="/eltres/changePWD">パスワード変更</Nav.Link>
                            <Nav.Link onClick={() => uId ? Logout(uId) : moveTo('/eltres/login')}>ログアウト</Nav.Link>
                        </Nav>
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
            </Navbar>
            <div className="container-fluid">
                <div className="row ">
                    <div className="col-md-12">
                        <h2>ダウンロード</h2>
                        <label>端末：</label>
                        <select ref={ref_dev} style={{ width: '200px', height: '30px', margin: '8px' }}>
                            {allDevicesData.length > 0 ?
                                (allDevicesData.map((device: any) => (
                                    <option key={device.dev_id} value={device.dev_id}>{device.dev_name}</option>
                                ))) : (<option disabled>端末の情報がありません。</option>)
                            }
                        </select>
                        <br />
                        <label className="dateLable" style={{ cursor: 'pointer', marginRight: '40px' }}>
                            期間：
                            <DatePicker
                                dateFormat="yyyy/MM/dd HH:mm"
                                selected={startDate}
                                onChange={(date: Date) => {
                                    setStartDate(date);
                                    if (endDate && date && date.toDateString() === endDate.toDateString()) {
                                        if (date.getTime() > endDate.getTime()) {
                                            setEndDate(date);
                                        }
                                    }
                                }}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                maxDate={endDate}
                                showTimeInput
                                timeFormat="HH:mm"
                                timeIntervals={1}
                                timeCaption="時間"
                            />
                        </label>
                        ～
                        <label className="dateLable" style={{ cursor: 'pointer' }}>
                            <DatePicker
                                className=""
                                dateFormat="yyyy/MM/dd HH:mm"
                                selected={endDate}
                                onChange={(date: Date) => {
                                    if (startDate && date && date.toDateString() === startDate.toDateString()) {
                                        if (date.getTime() < startDate.getTime()) {
                                            alert("終了時間は開始時間より後に選択してください。");
                                            return;
                                        }
                                    }
                                    setEndDate(date);
                                }}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                minDate={startDate} // 終了日の選択時、開始日より前の日付が選択できないこと
                                maxDate={new Date()}
                                showTimeInput
                                timeFormat="HH:mm"
                                timeIntervals={1}
                                timeCaption="時間"
                            />
                        </label>
                        <br />
                        <button className='btn btn-warning' style={{ marginTop: '20px' }} onClick={handleDownloadClick}>ダウンロード</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Download;