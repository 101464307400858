//
// ログイン画面
//
import { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import showPwdImg from './img/show-password.svg';
import hidePwdImg from './img/hide-password.svg';
import { resetAlertShown, getUserData, getClientData } from './ELTRES_cmnFunc';

// ログインチェック
async function LoginCheck(userid: string, passwd: string) {
  let bdy = JSON.stringify({ login_id: userid, password: passwd });
  const respons = await fetch("/api/v1/auth/login", {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'accept': 'application/json',
    },
    body: bdy,
  });
  // console.log(respons)

  if (respons.ok) {
    const result = await respons.json();
    const res = JSON.stringify(result);
    sessionStorage.setItem('u_id', res);
    // console.log('res = '+ res);

    const uId = JSON.parse(res).u_id;
    await checkAuthorityType(uId);
    resetAlertShown();
    // console.log('uId = '+ uId);
    return uId;

  } else {
    console.log(respons.status)
    return null;
  }
}

async function checkAuthorityType(uId: string) {
  let url = '/api/v1/user/'
  url = url + uId;

  const userInfoResponse = await fetch(url, {
    method: 'GET',
    headers: {
      'accept': 'application/json',
    }
  });

  if (userInfoResponse.ok) {
    const userInfo = await userInfoResponse.json();
    sessionStorage.setItem('authority', userInfo.authority);
    sessionStorage.setItem('client_id', userInfo.client_id);
    return null;
  } else {
    throw new Error('Failed to fetch authorityType. Occured:' + userInfoResponse.status);
  }
}

export function Login(): JSX.Element {
  const navigate = useNavigate();
  const ref_user = useRef<HTMLInputElement>(null!);
  const ref_pass = useRef<HTMLInputElement>(null!);
  const [isRevealPwd, setIsRevealPwd] = useState(false);

  async function handleLoginClick(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    let emsg = '';
    let userId = ref_user.current.value;
    let passWd = ref_pass.current.value;

    if (userId == '' || passWd == '') {
      alert('ユーザーID、パスワードを入力してください。');
      return;
    }

    // 入力値チェック
    const ret = await LoginCheck(userId, passWd);
    if (ret == null) {
      emsg = 'ユーザーID、又はパスワードに誤りがあります。\n';
      alert(emsg);
      ref_pass.current.value = '';
      return;
    }

    const user = await getUserData(parseInt(ret));
    if (user.client_id != null) {
      const client = await getClientData(user.client_id);
      if (client.enable == false) {
        alert('このアカウントが無効なため、ログインできません。');
        return;
      }
      console.log(client);
    }
    navigate('/eltres/tracking');
  }

  return (
    <>
      <div className="Auth-form-container">
        <form className="Auth-form">
          <div className="Auth-form-content">
            <h3 className="Auth-form-title">ELTRES</h3>
            <div className="form-group mt-3">
              <input
                type="text"
                className="form-control mt-1"
                placeholder="ログインID"
                ref={ref_user}
              />
            </div>

            <div className="form-group mt-3">
              <div className="password-input">
                <input
                  type={isRevealPwd ? "text" : "password"}
                  className="form-control mt-1"
                  placeholder="パスワード"
                  ref={ref_pass}
                />
                <img
                  className="password-toggle"
                  title={isRevealPwd ? "Hide password" : "Show password"}
                  src={isRevealPwd ? showPwdImg : hidePwdImg}
                  onClick={() => setIsRevealPwd(prevState => !prevState)}
                />
              </div>
            </div>
            <div className="d-grid gap-2 mt-3">
              <button type="submit" className="btn btn-submit" onClick={(event) => handleLoginClick(event)}>
                ログイン
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default Login;
