import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Tracking } from './ELTRES_tracking';
import { MovingHistory } from './ELTRES_movingHistory';
import { AddUser } from './ELTRES_addUser';
import { AddClient } from './ELTRES_addClient';
import { AddDevice } from './ELTRES_addDevice';
import { AddPayload } from './ELTRES_addPayload';
import { Login } from './ELTRES_login';
import { ChangePWD } from './ELTRES_changePWD';
import { Download } from './ELTRES_download';
import { UsageAmount } from './ELTRES_usageAmount';



function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <div>
        <Routes>
          <Route path="*" element={<Login />} />
          <Route path="/eltres/tracking" element={<Tracking />} />
          <Route path="/eltres/movingHistory" element={<MovingHistory />} />
          <Route path="/eltres/users" element={<AddUser />} />
          <Route path="/eltres/clients" element={<AddClient />} />
          <Route path="/eltres/devices" element={<AddDevice />} />
          <Route path="/eltres/payloads" element={<AddPayload />} />
          <Route path="/eltres/changePWD" element={<ChangePWD />} />
          <Route path="/eltres/download" element={<Download />} />
          <Route path="/eltres/usageAmount" element={<UsageAmount />} />
        </Routes>
       </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
