import { FullUserData, ClientData, DeviceData, PayloadData, PayloadSourceData, UsageRecord } from './ELTRES_cmnDefine';

export const moveTo = ((path: string) => {
    window.location.href = path;
});

//--------------------if auto logout occured,alert the user--------------------
let isAlertShown = false;
export function autoLogoutAlert() {
    if (!isAlertShown) {
        alert('エラーが発生しました。\nまたは、一定期間利用されていないため、自動的にログアウトしました。\nログイン画面に移動します。');
        isAlertShown = true;
        moveTo('/eltres/login');
    }
};

export function resetAlertShown() {
    isAlertShown = false;
}

//--------------------logout API--------------------
export async function Logout(uId: string) {
    // console.log(uId)
    try {
        if (uId != null) {
            // @ts-ignore
            const ssinfo: sessionInfo = JSON.parse(uId);
            const respons = await fetch("/api/v1/auth/logout", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json',
                },
            });
            sessionStorage.removeItem('u_id');
        }
        resetAlertShown();
        moveTo('/eltres/login');
    } catch (error) {
        console.error('Failed to logout. Occured :' + error);
        autoLogoutAlert();
    }
}

//------------------------------download CSV file------------------------------
export function convertToCSV(data: any[]) {
    const headers = Object.keys(data[0]).join(',') + '\n';
    const rows = data.map(obj => {
        const date = new Date(obj.data_date);
        const formattedDate = DateFormatWithSlash(date);
        const values = Object.values(obj).map(val => (val === obj.data_date ? formattedDate : val));
        return values.join(',');
    }).join('\n');

    return headers + rows;
}

export async function downloadCSV(fileName: String, graphData: any[]) {
    const csvData = convertToCSV(graphData);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${fileName}.csv`;
    a.click();
    window.URL.revokeObjectURL(url);
}
//--------------------changePW API--------------------
export async function ChangePW(uId: string, passWd: Object) {
    try {
        const response = await fetch('/api/v1/auth/password', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
            },
            body: JSON.stringify(passWd),
        });
        console.log(`Response status: ${response.status}`);
        if (response.ok) {
            console.log("Success")
            return "Success";
        } else {
            const errorMsg = await response.text();
            console.error("Failed to change password. Error:", errorMsg);
            return "Failed";
        }
    } catch (error) {
        console.error("Error during password change:", error);
        return "Failed";
    }
}

//------------------yyyy/MM/dd hh:mm:ss------------------
export function DateFormatWithSlash(date: Date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    const formattedDate = `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;

    return formattedDate;
};

//--------------------yyyyMMdd--------------------
export function DateFormatOnlyDate(date: Date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const formattedDate = `${year}${month}${day}`;

    return formattedDate;
};

//--------------------yyyy-MM-dd--------------------
export function TimestampToDate(timestamp?: string | null) {
    if (!timestamp) return '';

    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
}

//--------------------yyyy/MM/dd 00:00:00-------------------
export function FormatStartDate(startDate: Date) {
    const formated = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0, 0, 0);
    return formated;
}

//--------------------yyyy/MM/dd 23:59:59-------------------
export function FormatEndDate(endDate: Date) {
    const formated = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 23, 59, 0, 0);
    return formated;
}
//--------------------yyyy/01/01 00:00:00-------------------
export function FormatStartDateByYear(startDate: Date) {
    return new Date(startDate.getFullYear(), 0, 1, 0, 0, 0, 0);
}

//--------------------yyyy/12/31 23:59:59-------------------
export function FormatEndDateByYear(endDate: Date) {
    return new Date(endDate.getFullYear(), 11, 31, 23, 59, 59, 999);
}

export function calculateVoltage(src_02: number) {
    const voltage = (1.85 / 1023) * 4 * src_02;
    const formattedVoltage = Math.floor(voltage * 1000) / 1000;
    return `${formattedVoltage}V`;
}

//-----------------------------------------------------------------------//
//                                  User                                 //
//-----------------------------------------------------------------------//

//------------------get usersList------------------
export async function getAllUsersData() {
    const allUsersResponse = await fetch(`/api/v1/users`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        },
    });

    if (allUsersResponse.ok) {
        const allUsersData = await allUsersResponse.json();
        // console.log('allUsersData1:', allUsersData);
        return allUsersData;
    } else {
        autoLogoutAlert();
        throw new Error('Failed to fetch allUsersData. Occured:' + allUsersResponse.status);
    }

}

//------------------get UserData------------------
export async function getUserData(uId: number) {
    const userResponse = await fetch(`/api/v1/user/${uId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        },
    });

    if (userResponse.ok) {
        const userData = await userResponse.json();
        // console.log('allUsersData:', userData);
        return userData;
    } else {
        autoLogoutAlert();
        throw new Error('Failed to fetch userData. Occured:' + userResponse.status);
    }
}

//------------------add new User------------------
export async function addNewUser(user: FullUserData) {
    const respons = await fetch('/api/v1/user', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        },
        body: JSON.stringify(user),
    });

    if (respons.ok) {
        console.log(user)
        const allUsersData = getAllUsersData();
        console.log('addNewUser success.')
        return allUsersData;
    } else {
        console.error("Fail to add user. Occured:" + respons.status)
        if (respons.status == 409) {
            alert(respons.status + 'エラーが発生しました。\nこのページを再読み込みします。もう一度お試しください。')
        } else {
            autoLogoutAlert();
        }
    }
}
//------------------update User------------------
export async function updateUser(updateUser: Partial<FullUserData>) {
    const response = await fetch(`/api/v1/user/${updateUser.u_id}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        },
        body: JSON.stringify(updateUser),
    });
    // console.log(response)

    if (response.ok) {
        window.alert('ユーザーの情報を変更しました。')
        console.log("After update :" + JSON.stringify(updateUser));
        const allUsersData = getAllUsersData();
        return allUsersData;
    } else {
        console.error('Failed to update user. Error:', response.status);
        autoLogoutAlert();
    }
}

//------------------delete User------------------
export async function deleteUser(u_id: number, login_id: string) {
    const response = await fetch(`/api/v1/user/${u_id}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        },
    });

    if (response.ok) {
        console.log('ユーザー（ login_id：' + login_id + ' ）が削除されました。');
        window.alert('ユーザー（ ログインID：' + login_id + ' ）が削除されました。');
        return response;
    } else {
        console.error('ユーザー（ login_id：' + login_id + ' ）の削除に失敗しました。エラー:', response.status);
        autoLogoutAlert();
    }
}

//-----------------------------------------------------------------------//
//                                 Client                                //
//-----------------------------------------------------------------------//

//------------------get ClientsList------------------
export async function getAllClientsDate() {
    const allClientsResponse = await fetch(`/api/v1/clients`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        },
    });

    if (allClientsResponse.ok) {
        const allClientsData = await allClientsResponse.json();
        // console.log('allClientsData:', allClientsData);
        return allClientsData;
    } else {
        autoLogoutAlert();
        throw new Error('Failed to fetch allClientsData. Occured:' + allClientsResponse.status);
    }

}

//------------------get ClientData------------------
export async function getClientData(client_id: number) {
    const clientResponse = await fetch(`/api/v1/client/${client_id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        },
    });

    if (clientResponse.ok) {
        const clientData = await clientResponse.json();
        // console.log('clientData:', clientData);
        return clientData;
    } else {
        autoLogoutAlert();
        throw new Error('Failed to fetch clientData. Occured:' + clientResponse.status);
    }
}

//------------------add new Client------------------
export async function addNewClient(client: ClientData) {
    const respons = await fetch('/api/v1/client', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        },
        body: JSON.stringify(client),
    });

    if (respons.ok) {
        window.alert('取引先を追加しました。')
        const allClientsData = getAllClientsDate();
        console.log('addNewClient success.')
        return allClientsData;
    } else {
        console.error("Fail to add client. Occured:" + respons.status)
        if (respons.status == 409) {
            alert(respons.status + 'エラーが発生しました。\nこのページを再読み込みします。もう一度お試しください。')
        } else {
            autoLogoutAlert();
        }
    }
}

//------------------update Client------------------
export async function updateClient(updateClient: Partial<ClientData>) {
    const response = await fetch(`/api/v1/client/${updateClient.client_id}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        },
        body: JSON.stringify(updateClient),
    });
    // console.log(response.status)

    if (response.ok) {
        const allClientsData = getAllClientsDate();
        console.log("After update Client :" + JSON.stringify(updateClient));
        window.alert('取引先の情報を変更しました。')
        return allClientsData;
    } else {
        console.error('Failed to update client. Error:', response.status);
        autoLogoutAlert();
    }
}

//------------------delete Client------------------
export async function deleteClient(client_id: number, name_knj: string) {
    const response = await fetch(`/api/v1/client/${client_id}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        },
    });

    if (response.ok) {
        console.log('取引先（ 会社名：' + name_knj + ' ）が削除されました。');
        window.alert('取引先（ 会社名：' + name_knj + ' ）が削除されました。');
        return response;
    } else {
        console.error('取引先（ 会社名：' + name_knj + ' ）の削除に失敗しました。エラー:', response.status);
        autoLogoutAlert();
    }
}

//-----------------------------------------------------------------------//
//                                 Device                                //
//-----------------------------------------------------------------------//

//------------------get DevicesList------------------
export async function getAllDevicesDate() {
    const allDevicesResponse = await fetch(`/api/v1/devices`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        },
    });

    if (allDevicesResponse.ok) {
        const allDevicesData = await allDevicesResponse.json();
        // console.log('allDevicesData:', allDevicesData);
        return allDevicesData;
    } else {
        autoLogoutAlert();
        throw new Error('Failed to fetch allDevicesData. Occured:' + allDevicesResponse.status);
    }

}

//------------------get DeviceData------------------
export async function getDeviceData(dev_id: number) {
    const deviceResponse = await fetch(`/api/v1/device/${dev_id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        },
    });

    if (deviceResponse.ok) {
        const deviceData = await deviceResponse.json();
        // console.log('deviceData:', deviceData);
        return deviceData;
    } else {
        autoLogoutAlert();
        throw new Error('Failed to fetch deviceData. Occured:' + deviceResponse.status);
    }
}

//------------------add new Device------------------
export async function addNewDevice(device: DeviceData) {
    try {
        const respons = await fetch('/api/v1/device', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
            },
            body: JSON.stringify(device),
        });

        // console.log(respons)
        if (respons.ok) {
            window.alert('新規端末を追加しました。')
            console.log('addNewDevice success.')
            const allDevicesData = getAllDevicesDate();
            return allDevicesData;
        } else {
            console.error("Fail to add Device. Occured:" + respons.status)
            if (respons.status == 409) {
                alert(respons.status + 'エラーが発生しました。\nこのページを再読み込みします。もう一度お試しください。')
            } else {
                autoLogoutAlert();
            }
        }
    } catch (error) {
        console.error("Error in addNewDevice:", error);
    }
}
//------------------update Device------------------
export async function updateDevice(updateDevice: Partial<DeviceData>) {
    const response = await fetch(`/api/v1/device/${updateDevice.dev_id}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        },
        body: JSON.stringify(updateDevice),
    });
    // console.log(response.status)

    if (response.ok) {
        const allDevicesData = getAllDevicesDate();
        console.log("After update Device :" + JSON.stringify(updateDevice));
        window.alert('端末の情報を変更しました。')
        return allDevicesData;
    } else {
        console.error('Failed to update Device. Error:', response.status);
        autoLogoutAlert();
    }
}

//------------------delete Device------------------
export async function deleteDevice(dev_id: number, dev_name: string, lfourid: number) {
    const response = await fetch(`/api/v1/device/${dev_id}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        },
    });

    if (response.ok) {
        console.log('端末（ 端末名：' + dev_name + '；lfourid：' + lfourid + ' ）が削除されました。');
        window.alert('端末（ 端末名：' + dev_name + '；lfourid：' + lfourid + ' ）が削除されました。');
        return response;
    } else {
        console.error('端末（ 端末名：' + dev_name + '；lfourid：' + lfourid + ' ）の削除に失敗しました。エラー:', response.status);
        autoLogoutAlert();
    }
}

//-----------------------------------------------------------------------//
//                                Payload                                //
//-----------------------------------------------------------------------//

//------------------get PayloadsList------------------
export async function getAllPayloadsDate() {
    const allPayloadsResponse = await fetch(`/api/v1/payloads`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        },
    });

    if (allPayloadsResponse.ok) {
        const allPayloadsData = await allPayloadsResponse.json();
        // console.log('allPayloadsData:', allPayloadsData);
        return allPayloadsData;
    } else {
        autoLogoutAlert();
        throw new Error('Failed to fetch allPayloadsData. Occured:' + allPayloadsResponse.status);
    }

}

//------------------get PayloadData------------------
export async function getPayloadData(payload_id: number) {
    const payloadResponse = await fetch(`/api/v1/payload/${payload_id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        },
    });

    if (payloadResponse.ok) {
        const payloadData = await payloadResponse.json();
        // console.log('payloadData:', payloadData);
        return payloadData;
    } else {
        autoLogoutAlert();
        throw new Error('Failed to fetch PayloadData. Occured:' + payloadResponse.status);
    }
}

//------------------add new Payload------------------
export async function addNewPayload(payload: PayloadData) {
    const respons = await fetch('/api/v1/payload', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        },
        body: JSON.stringify(payload),
    });

    if (respons.ok) {
        const responseData = await respons.json();
        const payloadId = responseData.payload_id;
        console.log('addNewPayload success.')
        return payloadId;
    } else {
        console.error("Fail to add Payload. Occured:" + respons.status)
        if (respons.status == 409) {
            alert(respons.status + 'エラーが発生しました。\nこのページを再読み込みします。もう一度お試しください。')
        } else {
            autoLogoutAlert();
        }
    }
}
//------------------update Payload------------------
export async function updatePayload(updatePayload: Partial<PayloadData>) {
    const response = await fetch(`/api/v1/payload/${updatePayload.payload_id}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        },
        body: JSON.stringify(updatePayload),
    });
    // console.log(response.status)

    if (response.ok) {
        const allPayloadsData = getAllPayloadsDate();
        console.log("After update Payload :" + JSON.stringify(updatePayload));
        return allPayloadsData;
    } else {
        console.error('Failed to update Payload. Error:', response.status);
        autoLogoutAlert();
    }
}

//------------------delete Payload------------------
export async function deletePayload(payload_id: number, payload_name: string) {
    const response = await fetch(`/api/v1/payload/${payload_id}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        },
    });

    if (response.ok) {
        console.log('ペイロード（ ペイロード名：' + payload_name + ' ）が削除されました。');
        window.alert('ペイロード（ ペイロード名：' + payload_name + ' ）が削除されました。');
        return response;
    } else {
        console.error('ペイロード（ ペイロード名：' + payload_name + ' ）の削除に失敗しました。エラー:', response.status);
        autoLogoutAlert();
    }
}

//-----------------------------------------------------------------------//
//                             PayloadSource                             //
//-----------------------------------------------------------------------//

//------------------get PayloadSourcesList------------------
export async function getAllPayloadSourcesDate() {
    const allPayloadSourcesResponse = await fetch(`/api/v1/payloadsources`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        },
    });

    if (allPayloadSourcesResponse.ok) {
        const allPayloadSourcesData = await allPayloadSourcesResponse.json();
        // console.log('allPayloadSourcesData:', allPayloadSourcesData);
        return allPayloadSourcesData;
    } else {
        autoLogoutAlert();
        throw new Error('Failed to fetch allPayloadSourcesData. Occured:' + allPayloadSourcesResponse.status);
    }

}

//------------------get PayloadSourceData------------------
export async function getPayloadSourceData(seq_no: number) {
    const payloadSourceResponse = await fetch(`/api/v1/payloadsource/${seq_no}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        },
    });

    if (payloadSourceResponse.ok) {
        const payloadSourceData = await payloadSourceResponse.json();
        // console.log('payloadSourceData:', payloadSourceData);
        return payloadSourceData;
    } else {
        autoLogoutAlert();
        throw new Error('Failed to fetch PayloadData. Occured:' + payloadSourceResponse.status);
    }
}

//------------------add new PayloadSource------------------
export async function addNewPayloadSource(payloadSource: PayloadSourceData) {
    const response = await fetch('/api/v1/payloadsource', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        },
        body: JSON.stringify(payloadSource),
    });

    if (response.ok) {
        const responseData = await response.json();
        const seq_no = responseData.seq_no;
        console.log('addNewPayloadSource　(seq_no:' + seq_no + ' ) success.')
        return seq_no;
    } else {
        console.error("Fail to add PayloadSource. Occured:" + response.status)
        if (response.status == 409) {
            alert(response.status + 'エラーが発生しました。\nこのページを再読み込みします。もう一度お試しください。')
        } else {
            autoLogoutAlert();
        }
    }
}
//------------------update PayloadSource------------------
export async function updatePayloadSource(updatePayloadSource: Partial<PayloadSourceData>) {
    const response = await fetch(`/api/v1/payloadsource/${updatePayloadSource.seq_no}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        },
        body: JSON.stringify(updatePayloadSource),
    });

    if (response.ok) {
        const responseData = await response.json();
        const seq_no = responseData.seq_no;
        console.log("After update PayloadSource :" + JSON.stringify(updatePayloadSource));
        console.log('ペイロードソース（seq_no：' + seq_no + '）を更新しました。');
        return response.status;
    } else {
        console.error('Failed to update PayloadSource. Error:', response.status);
        return response.status;
    }
}

//------------------delete PayloadSource------------------
export async function deletePayloadSource(seq_no: number) {
    const response = await fetch(`/api/v1/payloadsource/${seq_no}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        },
    });

    if (response.ok) {
        console.log('ペイロードソース（ seq_no' + seq_no + ' ）が削除されました。');
        return response;
    } else {
        console.error('ペイロードソース（ seq_no' + seq_no + ' ）の削除に失敗しました。エラー:', response.status);
        autoLogoutAlert();
    }
}

//-----------------------------------------------------------------------//
//                     　　　        Data         　　                    //
//-----------------------------------------------------------------------//

//------------------get Data------------------
export async function getData(dev_id: number, startDate: string, endDate: string) {
    const url = `/api/v1/data/${dev_id}?fromdate=${startDate}&todate=${endDate}`;
    const dataResponse = await fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        },
    });

    if (dataResponse.ok) {
        const data = await dataResponse.json();
        return data;
    } else {
        autoLogoutAlert();
        throw new Error('Failed to fetch Data. Occurred:' + dataResponse.status);
    }
}


//------------------get DataCount------------------
export async function getDataCount(dev_id: number) {
    const dataCountResponse = await fetch(`/api/v1/data/${dev_id}/count`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        },
    });

    if (dataCountResponse.ok) {
        const dataCount = await dataCountResponse.json();
        // console.log('dataCount:', dataCount);
        return dataCount;
    } else {
        autoLogoutAlert();
        throw new Error('Failed to fetch DataCount. Occured:' + dataCountResponse.status);
    }
}

//------------------get LastestData------------------
export async function getLastestData(dev_id: number) {
    const dataResponse = await fetch(`/api/v1/lastestdata/${dev_id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        },
    });
    // console.log(dataResponse)

    if (dataResponse.ok) {
        const lastestData = await dataResponse.json();
        return lastestData;
    } else if (dataResponse.status == 404) {
        return null
    } else {
        autoLogoutAlert();
        throw new Error('Failed to fetch LastestData. Occured:' + dataResponse.status);
    }
}

//-----------------------------------------------------------------------//
//                     　　　       Usage         　　                    //
//-----------------------------------------------------------------------//

//------------------get Usage------------------
export async function getUsage(client_id: number, startDate: string, endDate: string) {
    const url = `/api/v1/usage/${client_id}?fromdate=${startDate}&todate=${endDate}`;
    const dataResponse = await fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        },
    });

    if (dataResponse.ok) {
        const data = await dataResponse.json();
        return data;
    } else {
        autoLogoutAlert();
        throw new Error('Failed to fetch Usage Data. Occurred:' + dataResponse.status);
    }
}

//------------------get UsageCount------------------
export async function getUsageCount(client_id: number, startDate: string, endDate: string) {
    const dataCountResponse = await fetch(`/api/v1/usage/${client_id}/count?fromdate=${startDate}&todate=${endDate}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        },
    });

    if (dataCountResponse.ok) {
        const dataCount = await dataCountResponse.json();
        // console.log('UsageCount:', UsageCount);
        return dataCount;
    } else if (dataCountResponse.status == 500) {
        return null
    } else {
        autoLogoutAlert();
        throw new Error('Failed to fetch UsageCount. Occured:' + dataCountResponse.status);
    }
}

//------------------add new UsageRecord------------------
export async function addUsageRecord(usage: UsageRecord) {
    const response = await fetch('/api/v1/usage', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
        },
        body: JSON.stringify(usage),
    });

    if (response.ok) {
        const responseData = await response.json();
        const seq_no = responseData.seq_no;
        // console.log('addUsageRecord (seq_no:' + seq_no + ' ) success.')
        return seq_no;
    } else {
        console.error("Fail to add UsageRecord. Occured:" + response.status)
        if (response.status == 409) {
            alert(response.status + 'エラーが発生しました。\nこのページを再読み込みします。もう一度お試しください。')
        } else {
            autoLogoutAlert();
        }
    }
}