import "./App.css";
import "leaflet/dist/leaflet.css";
import React, { useEffect, useState, useRef } from "react";
import { Nav, Navbar, Offcanvas, Modal, Button } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";
import { Logout, autoLogoutAlert, getAllUsersData, deleteUser, addNewUser, getAllClientsDate, updateUser, getAllDevicesDate, moveTo } from './ELTRES_cmnFunc';
import showPwdImg from './img/show-password.svg';
import hidePwdImg from './img/hide-password.svg';
import { FullUserData, UserData, ClientData, DeviceData } from "./ELTRES_cmnDefine";

interface Radio {
    label: string
    value: number
}

const radioButtons: Radio[] = [
    { label: "システム権限", value: 0 },
    { label: "管理者権限", value: 1 },
    { label: "一般", value: 2 }
]


export function AddUser(): JSX.Element {
    const uId = sessionStorage.getItem('u_id')?.toString();
    const authSess = sessionStorage.getItem('authority')?.toString();
    const cIDSess = sessionStorage.getItem('client_id')?.toString();
    const [showModal, setShowModal] = useState(false);
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [isEnabled, setIsEnabled] = useState(true);
    const [authority, setAuthority] = useState(authSess ? parseInt(authSess) : 0);
    const ref_loginID = useRef<HTMLInputElement>(null!);
    const ref_pass = useRef<HTMLInputElement>(null!);
    const ref_knj = useRef<HTMLInputElement>(null!);
    const ref_kana = useRef<HTMLInputElement>(null!);
    const ref_client = useRef<HTMLSelectElement>(null!);
    const ref_remk = useRef<HTMLTextAreaElement>(null!);
    const ref_updateLoginID = useRef<HTMLInputElement>(null!);
    const ref_updatePass = useRef<HTMLInputElement>(null!);
    const ref_updateKnj = useRef<HTMLInputElement>(null!);
    const ref_updateKana = useRef<HTMLInputElement>(null!);
    const ref_updateClient = useRef<HTMLSelectElement>(null!);
    const ref_updateRemk = useRef<HTMLTextAreaElement>(null!);
    const changeAuthorityValue = (event: React.ChangeEvent<HTMLInputElement>) => setAuthority(Number(event.target.value));
    const checkEnableValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsEnabled(event.target.checked);
    };
    const [allUsersData, setAllUsersData] = useState<UserData[]>([]);
    const [fullUserData, setFullUserData] = useState<FullUserData[]>([]);
    const [selectedUser, setSelectedUser] = useState<FullUserData | null>(null);
    const [allClientsData, setAllClientsData] = useState<ClientData[]>([]);
    const [allDevicesData, setAllDevicesData] = useState<DeviceData[]>([]);
    const isClientAdmin = authSess == '1';


    //------------------renden page--------------------
    useEffect(() => {
        if (allUsersData.length == 0) {
            getAllUsersData()
                .then((data) => {
                    setAllUsersData(data);
                })
                .catch((error) => {
                    autoLogoutAlert()
                    console.error('Failed to getAllUsersDate. Occured : ' + error);
                })
        }

        if (allClientsData.length == 0) {
            getAllClientsDate()
                .then((data) => {
                    setAllClientsData(data);
                })
                .catch((error) => {
                    autoLogoutAlert()
                    console.error('Failed to getAllClientsDate. Occured : ' + error);
                })
        }

        if (allDevicesData.length == 0) {
            getAllDevicesDate()
                .then((data) => {
                    setAllDevicesData(data);
                })
                .catch((error) => {
                    autoLogoutAlert()
                    console.error('Failed to getAllDevicesDate. Occured : ' + error);
                })
        }
    }, [])

    useEffect(() => {
        try {
            const fetchData = async () => {
                const promises = allUsersData.map(async (user) => {
                    // console.log('user.u_id : '+ user.u_id)
                    const response = await fetch(`/api/v1/user/${user.u_id}`);
                    const userData = await response.json();
                    const fullUserData: FullUserData = {
                        ...user,
                        password: userData.password ? userData.password : "",
                        client_id: userData.client_id ? userData.client_id : null,
                        name_knj: userData.name_knj,
                        name_kana: userData.name_kana,
                        enable: userData.enable,
                        remark: userData.remark,
                    };
                    return fullUserData;
                });

                let fullUserDataArray = await Promise.all(promises);
                if (isClientAdmin && cIDSess) {
                    fullUserDataArray = fullUserDataArray.filter(user => user.client_id == parseInt(cIDSess))
                }
                setFullUserData(fullUserDataArray);
                // console.log(fullUserDataArray);
            };
            if (allUsersData.length > 0) {
                fetchData();
            }
        } catch (error) {
            autoLogoutAlert();
            console.error("Failed to fetch allUsersData. Occured : " + error)
        }
    }, [allUsersData]);

    //------------Clear Form-----------------
    function handleClearClick() {
        try {
            if (ref_loginID.current) ref_loginID.current.value = '';
            if (ref_pass.current) ref_pass.current.value = '';
            if (ref_client.current) ref_client.current.value = '';
            if (ref_knj.current) ref_knj.current.value = '';
            if (ref_kana.current) ref_kana.current.value = '';
            if (ref_remk.current) ref_remk.current.value = '';
            setAuthority(authSess ? parseInt(authSess) : 0);
            setIsEnabled(true);
        } catch (error) {
            console.error('Failed to handle clearClick. Occured : ' + error);
            autoLogoutAlert();
        }
    }

    //------------Add User-----------------
    async function handleAddClick(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
        let emsg = '';
        let clientIdValue = ref_client.current.value;
        let clientId = clientIdValue === "" ? null : parseInt(clientIdValue, 10);
        if (isClientAdmin && cIDSess) {
            clientId = parseInt(cIDSess)
        }
        if (authority == 0) {
            clientId = null;
        }

        const user: FullUserData = {
            u_id: 0, //auto index
            login_id: ref_loginID.current.value.trim(),
            password: ref_pass.current.value.trim(),
            client_id: clientId,
            authority: authority,
            name_knj: ref_knj.current.value.trim(),
            name_kana: ref_kana.current.value.trim(),
            remark: ref_remk.current.value.trim(),
            enable: isEnabled
        };

        const existingUser = allUsersData.find(u => u.login_id === user.login_id);
        if (existingUser) {
            emsg += '同じログインIDが既に存在します。\n'
        }

        if (user.login_id.length < 1) {
            emsg += '新規追加時、ユーザーIDは必須です。\n';
        }
        if (user.login_id.length < 5 || user.login_id.length > 16) {
            emsg += '新規追加時、ユーザーIDは5~16桁で入力してください。\n';
        }
        if (user.password.length < 1) {
            emsg += '新規追加時、パスワードは必須です。\n';
        }
        if (user.password.length < 5 || user.password.length > 16) {
            emsg += '新規追加時、パスワードは5~16桁で入力してください。\n';
        }
        if (/[^a-zA-Z0-9]/.test(user.password)){
            emsg += '新規追加時、パスワードは半角英数字のみを入力してください。\n';
        }
        if(!(/[a-zA-Z]/.test(user.password) && /\d/.test(user.password))){
            emsg += '新規追加時、パスワードは半角英数字を含めて入力してください。\n';
        }
        if (user.name_knj.length < 1) {
            emsg += '新規追加時、名前は必須です。\n';
        }
        if (user.authority == 1 && clientId == null) {
            emsg += '新規追加時、管理者権限の場合、取引先は必須です。\n';
        }

        if (emsg != '') {
            alert(emsg);
            return;
        }

        if (window.confirm('新規ユーザーを追加します。\nよろしいですか。')) {
            const allUsers = await addNewUser(user);
            if (allUsers !== undefined && allUsers !== null) {
                window.alert('新規ユーザーを追加しました。')
                setAllUsersData(allUsers);
                handleClearClick()
            }
            // console.log(user.client_id)
        }
    }

    //------------Update User-----------------
    function handleUpdateClick(user: FullUserData) {
        setSelectedUser(user);
        setIsEnabled(user.enable);
        setAuthority(user.authority);
        setShowModal(true);
    }

    async function handleUpdateUser(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
        let emsg = '';
        setShowModal(false);
        let clientIdValue = ref_updateClient.current.value;
        let clientId = clientIdValue === "" ? null : parseInt(clientIdValue, 10);
        if (isClientAdmin && cIDSess) {
            clientId = parseInt(cIDSess)
        }
        if (authority == 0) {
            clientId = null;
        }
        let updateUserData: Partial<FullUserData> = { ...selectedUser };
        updateUserData.login_id = ref_updateLoginID.current.value.trim();
        updateUserData.authority = authority;
        updateUserData.client_id = clientId;
        updateUserData.name_knj = ref_updateKnj.current.value.trim();
        updateUserData.name_kana = ref_updateKana.current.value.trim();
        updateUserData.remark = ref_updateRemk.current.value.trim();
        updateUserData.enable = isEnabled;

        if (ref_updatePass.current.value.length > 0) {
            updateUserData.password = ref_updatePass.current.value.trim();
        } else {
            delete updateUserData.password;
        }

        const existingUser = allUsersData.find(u => u.login_id === updateUserData.login_id && u.u_id !== updateUserData.u_id);
        if (existingUser) {
            emsg += '同じログインIDが既に存在します。\n'
        }
        if (updateUserData.login_id.length < 1) {
            emsg += 'ユーザーIDは必須です。\n';
        }
        if (updateUserData.name_knj.length < 1) {
            emsg += '名前は必須です。\n';
        }
        if (updateUserData.authority == 1 && clientId == null) {
            emsg += '管理者権限の場合、取引先は必須です。\n';
        }

        if (emsg != '') {
            alert(emsg);
            return;
        }
        if (window.confirm('ユーザーを更新します。\nよろしいですか。')) {
            const updateAllUsers = await updateUser(updateUserData);
            if (updateAllUsers !== undefined && updateAllUsers !== null) {
                setAllUsersData(updateAllUsers);
                handleClearClick();
            }
        }

        if(selectedUser?.client_id !== updateUserData.client_id){
            try{
                const updateDevs = allDevicesData.filter(dev => dev.u_id === updateUserData.u_id)
                // console.log(updateDevs)
                updateDevs.map(async (dev) =>{
                    const updateDev: Partial<DeviceData> = { ...dev };
                    if (dev.client_id == null){
                        updateDev.client_id = updateUserData.client_id
                    } else if (dev.client_id !== updateUserData.client_id){
                        updateDev.u_id = null
                    }
                    const response = await fetch(`/api/v1/device/${updateDev.dev_id}`, {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json',
                            'accept': 'application/json',
                        },
                        body: JSON.stringify(updateDev),
                    });
                })
            }catch(error){
                autoLogoutAlert();
                console.error("Failed to fetch update Dev from update User's client_id. Occured : " + error)
            }
        }
    }

    //------------Delete User-----------------

    async function handleDeleteUser(u_id: number, login_id: string) {
        let msg = 'ユーザー（ login_id：' + login_id + ' ）を削除します。よろしいですか。'

        const Occupied = allDevicesData.findIndex((dev) => {
            return dev.u_id === u_id;
        });
        // console.log('Occupied:', Occupied); 

        if (Occupied !== -1) {
            window.alert('ユーザー（ login_id：' + login_id + ' ）にはまだ装置が関連付けられています。先に装置とこのユーザーの関連付けを削除または解除してください。')
            return;
        }

        if (window.confirm(msg)) {
            const response = await deleteUser(u_id, login_id);
            if (response) {
                const updatedFullUserData = fullUserData.filter(user => user.u_id !== u_id);
                const updatedAllFullUserData = allUsersData.filter(user => user.login_id !== login_id);
                setFullUserData(updatedFullUserData);
                setAllUsersData(updatedAllFullUserData);
                if (selectedUser?.u_id == u_id) {
                    handleClearClick();
                }
            }
        }
    }


    return (
        <>
            <div className="App">
                <Navbar collapseOnSelect expand="lg" className="navbar">
                    <Navbar.Brand href="/eltres/tracking">ElTRES</Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Offcanvas placement="end" style={{ width: "200px" }}>
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title>
                                ElTRES
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Nav className="justify-content-end flex-grow-1 pe-3 nav-tab">
                                <Nav.Link href="/eltres/tracking">現在地</Nav.Link>
                                <Nav.Link href="/eltres/movingHistory">移動履歴</Nav.Link>
                                <Nav.Link href="/eltres/clients" hidden={isClientAdmin}>取引先設定</Nav.Link>
                                <Nav.Link href="/eltres/users" style={{ color: '#bbbcbd' }}>ユーザ設定</Nav.Link>
                                <Nav.Link href="/eltres/devices">端末設定</Nav.Link>
                                <Nav.Link href="/eltres/payloads" hidden={isClientAdmin}>ペイロード設定</Nav.Link>
                                <Nav.Link href="/eltres/usageAmount" hidden={isClientAdmin}>使用量</Nav.Link>
                                <Nav.Link href="/eltres/download">ダウンロード</Nav.Link>
                                <hr />
                                <Nav.Link href="/eltres/changePWD">パスワード変更</Nav.Link>
                                <Nav.Link onClick={() => uId? Logout(uId) : moveTo('/eltres/login') }>ログアウト</Nav.Link>
                            </Nav>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Navbar>
                <div className="container-fluid">
                    <div className="row ">
                        <div className="col-md-12">
                            <h2>新規｜ユーザ</h2>
                            <form className="row g-3">
                                <div className="col-md-3">
                                    <label className="form-label" >ログインID <span style={{ color: 'red', fontSize: 'small' }}>＊必須（5~16桁）</span> </label>
                                    <input type="text" className="form-control" ref={ref_loginID} autoComplete="off"/>
                                </div>
                                <div className="col-md-3 pwd-container">
                                    <label className="form-label" >パスワード <span style={{ color: 'red', fontSize: 'small' }}>＊必須（5~16桁の半角英数字の組み合わせ）</span> </label>
                                    <div className="password-input">
                                        <input type={isRevealPwd ? "text" : "password"} className="form-control" ref={ref_pass} autoComplete="new-password"/>
                                        <img
                                            title={isRevealPwd ? "Hide password" : "Show password"}
                                            src={isRevealPwd ? showPwdImg : hidePwdImg}
                                            onClick={() => setIsRevealPwd(prevState => !prevState)}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <label className="form-label">名前　漢字　<span style={{ color: 'red', fontSize: 'small' }}>＊必須</span></label>
                                    <input type="text" className="form-control" ref={ref_knj} />
                                </div>
                                <div className="col-md-3">
                                    <label className="form-label">名前　カナ</label>
                                    <input type="text" className="form-control" ref={ref_kana} />
                                </div>
                                <div className="col-md-3" hidden={isClientAdmin}>
                                    <label className="form-label">取引先　<span style={{ color: 'red', fontSize: 'small' }} hidden={authority != 1 }>＊必須</span></label>
                                    <select className="form-select" ref={ref_client} defaultValue='' disabled = {authority == 0}>
                                        <option value="" ></option>
                                        {allClientsData.map((client: ClientData) => (
                                            <option key={client.client_id} value={client.client_id} hidden={!client.enable}>{client.name_knj}</option>
                                        ))}
                                    </select>
                                </div>

                                <div className="col-md-3">
                                    <label className="form-label">権限種別</label>
                                    <br></br>
                                    {radioButtons.map(radio => {
                                        return (
                                            <div className="form-check form-check-inline" key={radio.value} hidden={radio.value == 0 && isClientAdmin ? true : false}>
                                                <input className="form-check-input" type="radio" name="authtype" value={radio.value} checked={radio.value == authority} onChange={changeAuthorityValue} style={{ height: "14px" }} />
                                                {radio.label}
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className="col-md-3">
                                    <label className="form-label">有　　効</label>
                                    <div className="form-check checkbox-enabled">
                                        <div className="form-check ">
                                            <input className="form-check-input" type="checkbox" name="enabled" defaultChecked onChange={checkEnableValue} style={{ height: "14px" }} />
                                            <label className="form-check-label">(チェックで有効)</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <label className="form-label">備　　考</label>
                                    <textarea className="form-control" name="remark" ref={ref_remk} style={{ height: "20px" }} />
                                </div>
                                <div className="col-md-3" style={isClientAdmin ? { paddingTop: '40px' } : {}}>
                                    <button type="submit" className="btn btn-success" onClick={(event) => handleAddClick(event)} style={{ marginRight: "10px" }}>ユーザー追加</button>
                                    <button type="submit" className="btn btn-secondary" onClick={handleClearClick}>クリア</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="card shadow" >
                        <div className="card-body p-4">
                            <div className="table-card">
                                {fullUserData.length > 0 ? (
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th className='widthNo'>No.</th>
                                                <th className='width10'>ログインID</th>
                                                <th className='width8'>名前(漢字)</th>
                                                <th className='width8'>名前(カナ)</th>
                                                <th className='width10' hidden={isClientAdmin}>取引先</th>
                                                <th className='width8'>権限種別</th>
                                                <th className='width8'>有効</th>
                                                <th className='width8'>備考</th>
                                                <th className='width8'>更新/削除</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {fullUserData.map((user, index) => (
                                                <tr key={user.u_id}>
                                                    <td>{index + 1}</td>
                                                    <td style={{ textAlign: 'left', padding: '10px' }}>{user.login_id}</td>
                                                    <td style={{ textAlign: 'left', padding: '10px' }}>{user.name_knj}</td>
                                                    <td style={{ textAlign: 'left', padding: '10px' }}>{user.name_kana}</td>
                                                    <td style={{ textAlign: 'left', padding: '10px' }} hidden={isClientAdmin}>
                                                        {allClientsData.find(client => client.client_id === user.client_id)?.name_knj || ""}
                                                    </td>
                                                    <td>
                                                        {radioButtons.find(radio => radio.value === user.authority)?.label}
                                                    </td>
                                                    <td>{user.enable ? "有効" : "無効"}</td>
                                                    <td style={{ textAlign: 'left', padding: '10px' }}>{user.remark}</td>
                                                    <td>
                                                        <button className='btn btn-warning' style={{ marginRight: '5px' }} onClick={() => handleUpdateClick(user)}>更新</button>
                                                        <button className='btn btn-danger' onClick={() => handleDeleteUser(user.u_id, user.login_id)}>削除</button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                ) : (
                                    <div style={{ color: 'red' }}>ユーザーの情報がありません。</div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <Modal show={showModal} onHide={() => setShowModal(false)} centered dialogClassName="modal-lg">
                    <Modal.Header closeButton>
                        <Modal.Title>編集</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row user-row">
                            <div className="col-md-12">
                                <form className="row g-3">
                                    <div className="col-4">
                                        <label className="form-label" >ログインID<span style={{ color: 'red', fontSize: 'small' }}>＊必須（5~16桁）</span></label>
                                        <input type="test" className="form-control" defaultValue={selectedUser ? selectedUser.login_id : ''} ref={ref_updateLoginID} autoComplete="off" />
                                    </div>

                                    <div className="col-4 pwd-container">
                                        <label className="form-label" >パスワード<span style={{ color: 'red', fontSize: 'small' }}>＊5~16桁の半角英数字</span></label>
                                        <div className="password-input">
                                            <input type={isRevealPwd ? "text" : "password"} className="form-control" ref={ref_updatePass} autoComplete="new-password" />
                                            <img
                                                title={isRevealPwd ? "Hide password" : "Show password"}
                                                src={isRevealPwd ? showPwdImg : hidePwdImg}
                                                onClick={() => setIsRevealPwd(prevState => !prevState)}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-4" hidden={isClientAdmin}>
                                        <label className="form-label">取引先<span style={{ color: 'red', fontSize: 'small' }} hidden={authority != 1 }>＊必須</span></label>
                                        <select className="form-select" ref={ref_updateClient} defaultValue={selectedUser ? selectedUser.client_id || "" : ""} disabled={ selectedUser?.authority == 0 || authority == 0}>
                                            <option value="" ></option>
                                            {allClientsData.map((client: ClientData) => (
                                                <option key={client.client_id} value={client.client_id} hidden={!client.enable}>{client.name_knj}</option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="col-4">
                                        <label className="form-label">名前　漢字<span style={{ color: 'red', fontSize: 'small' }}>＊必須</span></label>
                                        <input type="text" className="form-control" ref={ref_updateKnj} defaultValue={selectedUser ? selectedUser.name_knj : ''} />
                                    </div>

                                    <div className="col-4">
                                        <label className="form-label">名前　カナ</label>
                                        <input type="text" className="form-control" ref={ref_updateKana} defaultValue={selectedUser ? selectedUser.name_kana : ''} />
                                    </div>

                                    <div className="col-4">
                                        <label className="form-label">権限種別</label>
                                        <br></br>
                                        {radioButtons.map(radio => {
                                            return (
                                                <div className="form-check form-check-inline" key={radio.value} hidden={radio.value == 0 && isClientAdmin ? true : false}>
                                                    <input className="form-check-input" type="radio" name="authtype"
                                                        value={radio.value}
                                                        defaultChecked={radio.value == selectedUser?.authority}
                                                        disabled={uId && JSON.parse(uId).u_id == selectedUser?.u_id ? true : false}
                                                        onChange={changeAuthorityValue} style={{ height: "14px" }}
                                                    />
                                                    {radio.label}
                                                </div>
                                            )
                                        })}
                                    </div>

                                    <div className="col-4">
                                        <label className="form-label">有　　効</label>
                                        <div className="form-check checkbox-enabled">
                                            <div className="form-check ">
                                                <input className="form-check-input" type="checkbox" name="enabled" defaultChecked={selectedUser?.enable}
                                                    onChange={checkEnableValue}
                                                    disabled={uId && JSON.parse(uId).u_id == selectedUser?.u_id ? true : false}
                                                    style={{ height: "14px" }}
                                                />
                                                <label className="form-check-label">(チェックで有効)</label>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="col-4">
                                        <label className="form-label">備　　考</label>
                                        <textarea className="form-control" style={{ height: "20px" }} defaultValue={selectedUser ? selectedUser.remark : ''} ref={ref_updateRemk} />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={(event) => handleUpdateUser(event)}>保存</Button>
                        <Button variant="secondary" onClick={() => setShowModal(false)}>キャンセル</Button>
                    </Modal.Footer>
                </Modal>

            </div>
        </>
    );
}

export default AddUser;