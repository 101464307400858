import "./App.css";
import "leaflet/dist/leaflet.css";
import React, { useEffect, useState, useRef } from "react";
import { Nav, Navbar, Offcanvas } from 'react-bootstrap';
import L, { LatLng, Icon, Map } from "leaflet";
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { layer, library } from '@fortawesome/fontawesome-svg-core';
import { Logout, getAllDevicesDate, getLastestData, DateFormatWithSlash, calculateVoltage, moveTo, addUsageRecord } from './ELTRES_cmnFunc';
import { DeviceData, UsageRecord } from "./ELTRES_cmnDefine";


//--------------Data from DB-------------------
type Pins = { position: LatLng, name: string, devId: number, dateTime: string, adc: string, rssi: number, visible: boolean, color: string, initialColor: string }[];
const initialPins: { position: LatLng, name: string, devId: number, dateTime: string, adc: string, rssi: number, visible: boolean, color: string, initialColor: string }[] = [];

function createIcon(color: string) {
  return new Icon({
    iconUrl: require(`./img/${color}.png`),
    iconSize: [30, 30],
    iconAnchor: [12, 41],
    popupAnchor: [0, -41],
  });
}

function assignColorsToDevices(pins: Pins, availableColors: string[]) {
  const devIdToColorMap: { [devId: number]: string } = {};
  const updatedPins = pins.map((pin, index) => {
    let color: string;
    if (index < availableColors.length) {
      color = availableColors[index];
    } else {
      color = availableColors[index % availableColors.length];
    }

    devIdToColorMap[pin.devId] = color;

    return { ...pin, color, initialColor: color };
  });

  return { updatedPins, devIdToColorMap };
}
library.add(faMapMarkerAlt);

export function Tracking(): JSX.Element {
  const uId = sessionStorage.getItem('u_id')?.toString();
  const authority = sessionStorage.getItem('authority')?.toString();
  const cIDSess = sessionStorage.getItem('client_id')?.toString();
  const client_id = cIDSess && !isNaN(parseInt(cIDSess)) ? parseInt(cIDSess) : null;
  const u_id = uId ? JSON.parse(uId).u_id : 0;
  const savedLayer = localStorage.getItem('selectedLayer');
  const mapRef = React.createRef<HTMLDivElement>();
  const [map, setMap] = useState<Map | null>(null);
  const [layer, setLayer] = useState(savedLayer || '');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [allDevicesData, setAllDevicesData] = useState<DeviceData[]>([]);
  const [selectedDevIds, setSelectedDevIds] = useState<number[]>([]);
  const [pins, setPins] = useState(initialPins);
  const allColors: string[] = ['red', 'blue', 'green', 'orange', 'purple', 'pink', 'blueViolet', 'yellow', 'lightBlue', 'lightGreen'];
  const { updatedPins, devIdToColorMap } = assignColorsToDevices(pins, allColors);
  // let [occupiedColors, setOccupiedColors] = useState<string[]>([]);
  let [availableColors, setAvailableColors] = useState<string[]>([]);
  const [deviceDataAvailability, setDeviceDataAvailability] = useState<Record<number, boolean>>({});
  const showAdminLinks = authority !== '2';
  const isClientAdmin = authority == '1';
  const [markerRefs, setMarkerRefs] = useState<globalThis.Map<number, L.Marker[]>>(new globalThis.Map());
  const isFirstRender = useRef(true);

  const initialDevIds = initialPins.slice(0, allDevicesData.length).map((pin) => pin.devId);
  initialDevIds.forEach((devId, index) => {
    devIdToColorMap[devId] = allColors[index];
  });

  pins.forEach((pin) => {
    if (!pin.color) {
      pin.color = devIdToColorMap[pin.devId];
      pin.initialColor = pin.color;
    }
  });

  function calculateVisibleTiles(map: L.Map, client_id: number | null, uId: number) {
    const bounds = map.getBounds();
    const tileSize = 256;
    const nw = bounds.getNorthWest();
    const se = bounds.getSouthEast();

    const nwTile = map.project(nw, map.getZoom()).divideBy(tileSize).floor();
    const seTile = map.project(se, map.getZoom()).divideBy(tileSize).floor();

    const visibleTilesCount = (seTile.x - nwTile.x + 1) * (seTile.y - nwTile.y + 1);
    // console.log('Visible Tiles Count:', visibleTilesCount);
    localStorage.setItem('visibleTilesCount', JSON.stringify(visibleTilesCount));

    const usage: UsageRecord = {
      use_date: new Date().toISOString(),
      client_id: client_id,
      u_id: uId,
      event_type: 0,
      tile_count: visibleTilesCount
    };
    addUsageRecord(usage);
  };

  const fetchData = async () => {
    try {
      let devices = await getAllDevicesDate();
      if (devices) {
        devices = devices.filter((d: any) => d.enable === true);
        if (isClientAdmin && cIDSess) {
          devices = devices.filter((d: any) => d.client_id == parseInt(cIDSess));
        }

        let dataAvailability: Record<number, boolean> = {};
        const latestDataPromises = devices.map(async (device: DeviceData) => {
          const latestData = await getLastestData(device.dev_id);
          if (
            latestData !== null &&
            latestData.latitude <= 90 && latestData.latitude >= -90 &&
            latestData.longitude <= 180 && latestData.longitude >= -180
          ) {
            dataAvailability[device.dev_id] = true;
            return latestData;
          } else {
            dataAvailability[device.dev_id] = false;
            return null;
          }
        });
        const latestDataResults = (await Promise.all(latestDataPromises)).filter(Boolean); //filter null

        const newPins = latestDataResults.map(data => ({
          position: new LatLng(parseFloat(data.latitude.toFixed(6)), parseFloat(data.longitude.toFixed(6))),
          name: devices.find((device: DeviceData) => device.dev_id === data.dev_id)?.dev_name || 'Unknown',
          devId: data.dev_id,
          dateTime: DateFormatWithSlash(new Date(data.tx_time)),
          adc: calculateVoltage(data.src_02),
          rssi: data.rssi,
          visible: true,
          initialColor: '',
          color: ''
        }));
        const initialDevIds = devices.slice(0, 10).map((device: DeviceData) => device.dev_id);
        setSelectedDevIds(initialDevIds);
        setDeviceDataAvailability(dataAvailability);
        setAllDevicesData(devices);

        const { updatedPins, devIdToColorMap } = assignColorsToDevices(newPins, allColors);
        setPins(updatedPins);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (isFirstRender.current) {
      const visibleTilesCount = localStorage.getItem('visibleTilesCount');
      const usage: UsageRecord = {
        use_date: new Date().toISOString(),
        client_id: client_id,
        u_id: u_id,
        event_type: 0,
        tile_count: visibleTilesCount ? parseInt(visibleTilesCount) : 0
      };
      addUsageRecord(usage);
      isFirstRender.current = false;
      return;
    }

    if (mapRef.current) {
      const initialMap = L.map(mapRef.current);
      const storedZoom = localStorage.getItem('mapZoom');
      const storedCenterStr = localStorage.getItem('mapCenter');
      const storedCenter = storedCenterStr ? JSON.parse(storedCenterStr) : null;

      if (storedZoom && storedCenter) {
        initialMap.setView(storedCenter, parseInt(storedZoom));
      } else {
        initialMap.setView([34.3852, 132.4552], 15); //delault
      }

      initialMap.on('zoomend', () => {
        const currentZoom = initialMap.getZoom();
        localStorage.setItem('mapZoom', currentZoom.toString());
      });

      initialMap.on('moveend', () => {
        const currentCenter = initialMap.getCenter();
        calculateVisibleTiles(initialMap, client_id, u_id)
        localStorage.setItem('mapCenter', JSON.stringify([currentCenter.lat, currentCenter.lng]));
      });

      setMap(initialMap);

      //-----------prepare baselayers--------------
      const mapLayer = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: "Map data © <a href='https://openstreetmap.org'>OpenStreetMap</a> contributors"
      });

      const satelliteLayer = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
        attribution: "Map data © <a href='https://openstreetmap.org'>OpenStreetMap</a> contributors"
      });

      if (layer === '地図') {
        mapLayer.addTo(initialMap);
      } else {
        satelliteLayer.addTo(initialMap);
      }

      const layerControl = L.control.layers({
        '地図': mapLayer,
        '衛星写真': satelliteLayer
      }).addTo(initialMap);

      initialMap.on('baselayerchange', (event) => {
        // console.log('event : ' + event.name);
        setLayer(event.name);
      });

      const markers: L.Marker[] = [];

      pins.forEach((pin, index) => {
        if (pin.visible) {
          const marker = L.marker(pin.position, {
            icon: createIcon(pin.color),
            opacity: index < 10 ? 1 : 0
          })
            .addTo(initialMap)
            .bindPopup(`デバイス：${pin.name} <br /> 日付：${pin.dateTime} <br /> 緯度：${pin.position.lat} <br /> 経度：${pin.position.lng} <br /> 電池残量：${pin.adc} <br /> RSSI：${pin.rssi}`);

          setMarkerRefs(prevRefs => {
            const updatedRefs = new globalThis.Map(prevRefs);
            const markers = updatedRefs.get(pin.devId) || [];
            markers.push(marker);
            updatedRefs.set(pin.devId, markers);
            return updatedRefs;
          });
        } else {
          map?.eachLayer((layer) => {
            if (layer instanceof L.Marker && layer.getLatLng().equals(pin.position)) {
              map.removeLayer(layer);
            }
          })
        }
      });

      return () => {
        initialMap.remove();
        markers.forEach(marker => marker.remove());
      };
    }
  }, [pins]);

  useEffect(() => {
    localStorage.setItem('selectedLayer', layer);
    // console.log(layer);
  }, [layer]);

  useEffect(() => {
    const fetchLatestPositions = async () => {
      // console.log('更新')
      try {
        let dataAvailability: Record<number, boolean> = {};
        const latestDataPromises = allDevicesData.map(async (device: DeviceData) => {
          const latestData = await getLastestData(device.dev_id)
          if (latestData !== null &&
            latestData.latitude <= 90 && latestData.latitude >= -90 &&
            latestData.longitude <= 180 && latestData.longitude >= -180
          ) {
            dataAvailability[device.dev_id] = true;
            return latestData;
          } else {
            dataAvailability[device.dev_id] = false;
            return null;
          }
        });
        const latestDataResults = (await Promise.all(latestDataPromises)).filter(Boolean); //filter null

        const newPins = latestDataResults.map(data => ({
          position: new LatLng(parseFloat(data.latitude.toFixed(6)), parseFloat(data.longitude.toFixed(6))),
          name: allDevicesData.find((device: DeviceData) => device.dev_id === data.dev_id)?.dev_name || 'Unknown',
          devId: data.dev_id,
          dateTime: DateFormatWithSlash(new Date(data.tx_time)),
          adc: calculateVoltage(data.src_02),
          rssi: data.rssi,
          visible: selectedDevIds.includes(data.dev_id),
          initialColor: '',
          color: devIdToColorMap[data.dev_id] || ''
        }));
        setDeviceDataAvailability(dataAvailability);
        setPins(newPins);

        const visibleTilesCount = localStorage.getItem('visibleTilesCount');
        const usage: UsageRecord = {
          use_date: new Date().toISOString(),
          client_id: client_id,
          u_id: u_id,
          event_type: 0,
          tile_count: visibleTilesCount ? parseInt(visibleTilesCount) : 0
        };
        addUsageRecord(usage);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const intervalId = setInterval(fetchLatestPositions, 60000);

    return () => clearInterval(intervalId);
  }, [pins, selectedDevIds]);

  //----------------reset setting-------------------
  const reset = () => {
    fetchData();
  };

  //--------------ViewAllPins-------------------
  const handleViewAllClick = () => {
    const selectedPins = pins.filter(pin => selectedDevIds.includes(pin.devId))
    // console.log(selectedPins)
    if (map && selectedPins.length !== 0) {
      const bounds = L.latLngBounds(selectedPins.map(pin => pin.position));
      map.fitBounds(bounds);
    }
  };

  //--------------HandleVisibility-------------------
  const handleCheckboxChange = (devId: number, devColor: string, initialColor: string) => {
    if (selectedDevIds.includes(devId)) {
      const updatedDevIds = selectedDevIds.filter(id => id !== devId);
      // console.log(updatedDevIds)
      setSelectedDevIds(updatedDevIds);
      const markers = markerRefs.get(devId);
      if (markers) {
        markers.forEach(marker => marker.setOpacity(0));
      }
      availableColors.push(devColor)
      // console.log(availableColors)
    } else {
      if (selectedDevIds.length < 10) {
        setSelectedDevIds([...selectedDevIds, devId]);
        const markers = markerRefs.get(devId);
        let setNewColor = availableColors.includes(initialColor) ? initialColor : availableColors[0];
        const updatedIcon = createIcon(setNewColor)

        if (markers) {
          markers.forEach(marker => {
            marker.setIcon(updatedIcon);
            marker.setOpacity(1);

          });
        }
        availableColors = availableColors.filter(color => color !== setNewColor)
        setAvailableColors(availableColors)
        // console.log(availableColors)
      } else {
        alert('デバイスは最大で10個しか選択できません。');
      }
    }
  };


  return (
    <div className="App">
      <Navbar collapseOnSelect expand="lg" className="navbar">
        <Navbar.Brand href="/eltres/tracking">ElTRES</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Offcanvas placement="end" style={{ width: "200px" }}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              ElTRES
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-end flex-grow-1 pe-3 nav-tab">
              <Nav.Link href="/eltres/tracking" style={{ color: '#bbbcbd' }}>現在地</Nav.Link>
              <Nav.Link href="/eltres/movingHistory">移動履歴</Nav.Link>
              {showAdminLinks && (
                <>
                  <Nav.Link href="/eltres/clients" hidden={isClientAdmin}>取引先設定</Nav.Link>
                  <Nav.Link href="/eltres/users">ユーザ設定</Nav.Link>
                  <Nav.Link href="/eltres/devices">端末設定</Nav.Link>
                  <Nav.Link href="/eltres/payloads" hidden={isClientAdmin}>ペイロード設定</Nav.Link>
                  <Nav.Link href="/eltres/usageAmount" hidden={isClientAdmin}>使用量</Nav.Link>
                </>
              )}
              <Nav.Link href="/eltres/download">ダウンロード</Nav.Link>
              <hr />
              <Nav.Link href="/eltres/changePWD">パスワード変更</Nav.Link>
              <Nav.Link onClick={() => uId ? Logout(uId) : moveTo('/eltres/login')}>ログアウト</Nav.Link>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Navbar>

      <div className="map-container" ref={mapRef} style={{ position: 'relative', marginTop: '0px', width: '100%', height: '100vh' }}></div>

      <div className="pin-list">
        <button className="btn btn-viewAll" onClick={handleViewAllClick}>全面見る</button>
        <div className="custom-dropdown">
          <button className="btn btn-dropdown" onClick={() => setIsDropdownOpen(!isDropdownOpen)} >デバイス</button>
          {isDropdownOpen && (
            <div className="custom-dropdown-content">
              <button className="btn btn-reset" onClick={reset}>リセット</button>
              {allDevicesData.length > 0 ? (
                allDevicesData.map((device) => (
                  <div key={device.dev_id} style={{ cursor: 'pointer' }}>
                    <input
                      type="checkbox"
                      id={`checkbox-${device.dev_id}`}
                      className="checkbox-item"
                      checked={selectedDevIds.includes(device.dev_id)}
                      onChange={() => handleCheckboxChange(device.dev_id, pins.find(pin => pin.devId === device.dev_id)?.color || 'default', pins.find(pin => pin.devId === device.dev_id)?.initialColor || '')}
                    />
                    {device.dev_name}
                    {!deviceDataAvailability[device.dev_id] && <span style={{ color: 'red' }}>　✕</span>}
                  </div >
                ))
              ) : (
                <div style={{ color: 'red' }}>ユーザーに紐づけてる装置の情報がありません。</div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Tracking;
