import "./App.css";
import "leaflet/dist/leaflet.css";
import React, { useState, useRef, useEffect } from "react";
import { Nav, Navbar, Offcanvas } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";
import { Logout, getUserData, ChangePW, moveTo } from './ELTRES_cmnFunc';
import showPwdImg from './img/show-password.svg';
import hidePwdImg from './img/hide-password.svg';


export function ChangePWD(): JSX.Element {
    const uId = sessionStorage.getItem('u_id')?.toString();
    const authority = sessionStorage.getItem('authority')?.toString();
    const showAdminLinks = authority !== '2';
    const isClientAdmin = authority == '1';
    const ref_oldPass = useRef<HTMLInputElement>(null!);
    const ref_newPass = useRef<HTMLInputElement>(null!);
    const ref_newPassCheck = useRef<HTMLInputElement>(null!);
    const [isRevealOldPwd, setIsRevealOldPwd] = useState(false);
    const [isRevealNewPwd, setIsRevealNewPwd] = useState(false);
    const [isRevealNewPwdCheck, setIsRevealNewPwdCheck] = useState(false);

    async function handleChangePWClick() {
        const changePW = {
            old_password: ref_oldPass.current.value.trim(),
            new_password: ref_newPass.current.value.trim(),
            new_passwordCheck: ref_newPassCheck.current.value.trim()
        }

        if (changePW.old_password === '') {
            alert('旧パスワードを入力してください。');
            return;
        } else if (changePW.new_password === '') {
            alert('新パスワードを入力してください。');
            return;
        } else if (changePW.new_passwordCheck === '') {
            alert('新パスワード（確認）を入力してください。');
            return;
        } else if (/[^a-zA-Z0-9]/.test(changePW.new_password) || /[^a-zA-Z0-9]/.test(changePW.new_passwordCheck)) {
            alert('半角英数字のみを入力してください。');
            return;
        } else if (changePW.new_password.length < 5 || changePW.new_password.length > 16) {
            alert('新パスワードは5~16桁で入力してください。');
            return;
        } else if (!(/[a-zA-Z]/.test(changePW.new_password) && /\d/.test(changePW.new_password))) {
            alert('新パスワードは半角英数字を含めて入力してください。');
            return;
        } else if (changePW.new_passwordCheck.length < 5 || changePW.new_passwordCheck.length > 16) {
            alert('新パスワード（確認）は5~16桁で入力してください。');
            return;
        } else if (!(/[a-zA-Z]/.test(changePW.new_passwordCheck) && /\d/.test(changePW.new_passwordCheck))) {
            alert('新パスワード（確認）は半角英数字を含めて入力してください。');
            return;
        } else if (changePW.new_password !== changePW.new_passwordCheck) {
            alert('新パスワードと新パスワード（確認）が一致しません。');
            return;
        } else if (uId != null) {
            //for check whether be auto logouted or not 
            const parsedData = JSON.parse(uId);
            const userId = parsedData.u_id.toString();
            getUserData(userId);
            //-----------------------------------------

            const response = await ChangePW(uId, changePW)
            console.log(response)
            if (response === 'Success') {
                window.confirm('パスワードを変更しました。')
                moveBack();
            } else {
                window.confirm('パスワードの変更を拒否されました。\n入力されている情報と異なります。')
                handleClearClick()
            }
        }
    }

    function handleClearClick() {
        ref_oldPass.current.value = '';
        ref_newPass.current.value = '';
    }

    function moveBack() {
        moveTo('/eltres/tracking');
    }

    return (
        <div className="App">
            <Navbar collapseOnSelect expand="lg" className="navbar">
                <Navbar.Brand href="/eltres/tracking">ElTRES</Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Offcanvas placement="end" style={{ width: "200px" }}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>
                            ElTRES
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Nav className="justify-content-end flex-grow-1 pe-3 nav-tab">
                            <Nav.Link href="/eltres/tracking">現在地</Nav.Link>
                            <Nav.Link href="/eltres/movingHistory">移動履歴</Nav.Link>
                            {showAdminLinks && (
                                <>
                                    <Nav.Link href="/eltres/clients" hidden={isClientAdmin}>取引先設定</Nav.Link>
                                    <Nav.Link href="/eltres/users">ユーザ設定</Nav.Link>
                                    <Nav.Link href="/eltres/devices">端末設定</Nav.Link>
                                    <Nav.Link href="/eltres/payloads" hidden={isClientAdmin}>ペイロード設定</Nav.Link>
                                    <Nav.Link href="/eltres/usageAmount" hidden={isClientAdmin}>使用量</Nav.Link>
                                </>
                            )}
                            <Nav.Link href="/eltres/download">ダウンロード</Nav.Link>
                            <hr />
                            <Nav.Link href="/eltres/changePWD" style={{ color: '#bbbcbd' }}>パスワード変更</Nav.Link>
                            <Nav.Link onClick={() => uId ? Logout(uId) : moveTo('/eltres/login')}>ログアウト</Nav.Link>
                        </Nav>
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
            </Navbar>
            <div className="container-fluid">
                <div className="row ">
                    <div className="col-md-12">
                        <h2>パスワード変更</h2>
                        <div className="col-md-2 pwd-container">
                            <label className="form-label" >旧パスワード：</label>
                            <div className="password-input">
                                <input type={isRevealOldPwd ? "text" : "password"} className="form-control" ref={ref_oldPass} autoComplete="new-password" />
                                <img
                                    title={isRevealOldPwd ? "Hide password" : "Show password"}
                                    src={isRevealOldPwd ? showPwdImg : hidePwdImg}
                                    onClick={() => setIsRevealOldPwd(prevState => !prevState)}
                                />
                            </div>
                        </div>
                        <div className="col-md-2 pwd-container">
                            <label className="form-label" style={{ margin: '8px 0px 2px 0px' }}>新パスワード：</label>
                            <p style={{ fontSize: '10px', margin: '0px 0px 2px 0px' }}>※半角英数字の組み合わせ（5桁以上16桁以下）</p>
                            <div className="password-input">
                                <input type={isRevealNewPwd ? "text" : "password"} className="form-control" ref={ref_newPass} autoComplete="new-password" />
                                <img
                                    title={isRevealNewPwd ? "Hide password" : "Show password"}
                                    src={isRevealNewPwd ? showPwdImg : hidePwdImg}
                                    onClick={() => setIsRevealNewPwd(prevState => !prevState)}
                                />
                            </div>
                        </div>
                        <div className="col-md-2 pwd-container">
                            <label className="form-label" style={{ margin: '8px 0px 2px 0px' }}>新パスワード（確認）：</label>
                            <p style={{ fontSize: '10px', margin: '0px 0px 2px 0px' }}>※半角英数字の組み合わせ（5桁以上16桁以下）</p>
                            <div className="password-input">
                                <input type={isRevealNewPwdCheck ? "text" : "password"} className="form-control" ref={ref_newPassCheck} autoComplete="new-password" />
                                <img
                                    title={isRevealNewPwdCheck ? "Hide password" : "Show password"}
                                    src={isRevealNewPwdCheck ? showPwdImg : hidePwdImg}
                                    onClick={() => setIsRevealNewPwdCheck(prevState => !prevState)}
                                />
                            </div>
                        </div>
                        {/* <hr /> */}
                        <div className="col-md-3" style={isClientAdmin ? { paddingTop: '40px' } : {}}>
                            <button type="submit" className="btn btn-success" onClick={handleChangePWClick} style={{ margin: "10px 10px 10px 0px" }}>変更</button>
                            <button type="submit" className="btn btn-secondary" onClick={moveBack}>キャンセル</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ChangePWD;