import "./App.css";
import "leaflet/dist/leaflet.css";
import React, { useEffect, useState, useRef } from "react";
import { Nav, Navbar, Offcanvas, Modal, Button } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";
import { Logout, autoLogoutAlert, getAllUsersData, getAllClientsDate, getAllDevicesDate, getAllPayloadsDate, moveTo, addNewDevice, updateDevice, deleteDevice } from './ELTRES_cmnFunc';
import { FullUserData, UserData, DeviceData, ClientData, PayloadData } from "./ELTRES_cmnDefine";

export function AddDevice(): JSX.Element {
    const uId = sessionStorage.getItem('u_id')?.toString();
    const authority = sessionStorage.getItem('authority')?.toString();
    const cIDSess = sessionStorage.getItem('client_id')?.toString();
    const [showModal, setShowModal] = useState(false);
    const [isEnabled, setIsEnabled] = useState(true);
    const ref_client = useRef<HTMLSelectElement>(null!);
    const ref_user = useRef<HTMLSelectElement>(null!);
    const ref_devName = useRef<HTMLInputElement>(null!);
    const ref_lfourid = useRef<HTMLInputElement>(null!);
    const ref_svcTag = useRef<HTMLInputElement>(null!);
    const ref_brkHost = useRef<HTMLInputElement>(null!);
    const ref_topic = useRef<HTMLInputElement>(null!);
    const ref_profileNo = useRef<HTMLInputElement>(null!);
    const ref_payloadid = useRef<HTMLSelectElement>(null!);
    const ref_interval = useRef<HTMLSelectElement>(null!);
    const ref_remk = useRef<HTMLTextAreaElement>(null!);
    const ref_updateClient = useRef<HTMLSelectElement>(null!);
    const ref_updateUser = useRef<HTMLSelectElement>(null!);
    const ref_updateDevName = useRef<HTMLInputElement>(null!);
    const ref_updateLfourid = useRef<HTMLInputElement>(null!);
    const ref_updateSvcTag = useRef<HTMLInputElement>(null!);
    const ref_updateBrkHost = useRef<HTMLInputElement>(null!);
    const ref_updateTopic = useRef<HTMLInputElement>(null!);
    const ref_updateProfileNo = useRef<HTMLInputElement>(null!);
    const ref_updatePayloadid = useRef<HTMLSelectElement>(null!);
    const ref_updateInterval = useRef<HTMLSelectElement>(null!);
    const ref_updateRemk = useRef<HTMLTextAreaElement>(null!);
    const checkEnableValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsEnabled(event.target.checked);
    };
    const [allUsersData, setAllUsersData] = useState<UserData[]>([]);
    const [fullUserData, setFullUserData] = useState<FullUserData[]>([]);
    const [allClientsData, setAllClientsData] = useState<ClientData[]>([]);
    const [allPayloadsData, setAllPayloadsData] = useState<PayloadData[]>([]);
    const [selectedDevice, setSelectedDevice] = useState<DeviceData | null>(null);
    const [selectedUser, setSelectedUser] = useState<FullUserData[]>([]);
    const [allDevicesData, setAllDevicesData] = useState<DeviceData[]>([]);
    const isClientAdmin = authority == '1';
    const [hasShownAlert, setHasShownAlert] = useState(false);

    //------------------renden page--------------------
    useEffect(() => {
        if (allUsersData.length == 0) {
            getAllUsersData()
                .then((data) => {
                    setAllUsersData(data);
                })
                .catch((error) => {
                    autoLogoutAlert()
                    console.error('Failed to getAllUsersDate. Occured : ' + error);
                })
        }

        if (allClientsData.length == 0) {
            getAllClientsDate()
                .then((data) => {
                    setAllClientsData(data);
                })
                .catch((error) => {
                    autoLogoutAlert()
                    console.error('Failed to getAllClientsDate. Occured : ' + error);
                })
        }

        if (allDevicesData.length == 0) {
            getAllDevicesDate()
                .then((data) => {
                    if (isClientAdmin && cIDSess) {
                        data = data.filter((d: any) => d.client_id == parseInt(cIDSess))
                    }
                    setAllDevicesData(data);
                })
                .catch((error) => {
                    autoLogoutAlert()
                    console.error('Failed to getAllDevicesDate. Occured : ' + error);
                })
        }

        if (allPayloadsData.length == 0) {
            getAllPayloadsDate()
                .then((data) => {
                    setAllPayloadsData(data);
                })
                .catch((error) => {
                    autoLogoutAlert()
                    console.error('Failed to getAllPayloadsDate. Occured : ' + error);
                })
        }
    }, [])

    useEffect(() => {
        try {
            const fetchData = async () => {
                const promises = allUsersData.map(async (user) => {
                    // console.log('user.u_id : '+ user.u_id)
                    const response = await fetch(`/api/v1/user/${user.u_id}`);
                    const userData = await response.json();
                    const fullUserData: FullUserData = {
                        ...user,
                        password: userData.password ? userData.password : "",
                        client_id: userData.client_id ? userData.client_id : null,
                        name_knj: userData.name_knj,
                        name_kana: userData.name_kana,
                        enable: userData.enable,
                        remark: userData.remark,
                    };
                    return fullUserData;
                });

                let fullUserDataArray = await Promise.all(promises);
                if (isClientAdmin && cIDSess) {
                    fullUserDataArray = fullUserDataArray.filter(user => user.client_id == parseInt(cIDSess))
                }
                setFullUserData(fullUserDataArray);
                // console.log(fullUserDataArray);
            };
            if (allUsersData.length > 0) {
                fetchData();
            }
        } catch (error) {
            autoLogoutAlert();
            console.error("Failed to fetch allUsersData. Occured : " + error)
        }
    }, [allUsersData]);

    useEffect(() => {
        if (fullUserData.length > 0) {
            const usersNoClient = fullUserData.filter(user => user.client_id == null);
            setSelectedUser(usersNoClient);
        }
    }, [fullUserData]);

    //------------Clear Form-----------------
    function handleClearClick() {
        try {
            if (ref_devName.current) ref_devName.current.value = '';
            if (ref_lfourid.current) ref_lfourid.current.value = '';
            if (ref_client.current) ref_client.current.value = '';
            if (ref_user.current) ref_user.current.value = '';
            if (ref_payloadid.current) ref_payloadid.current.value = '';
            if (ref_svcTag.current) ref_svcTag.current.value = '';
            if (ref_brkHost.current) ref_brkHost.current.value = '';
            if (ref_topic.current) ref_topic.current.value = '';
            if (ref_profileNo.current) ref_profileNo.current.value = '';
            if (ref_interval.current) ref_interval.current.value = '';
            if (ref_remk.current) ref_remk.current.value = '';
            setIsEnabled(true);
        } catch (error) {
            console.error('Failed to handle clearClick. Occured : ' + error);
            autoLogoutAlert();
        }
    }

    //------------Add Device-----------------
    async function handleAddClick(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
        let emsg = '';
        let clientIdValue;
        let userIdValue;
        let intervalValue;

        if (ref_client.current && (ref_client.current.value == null || ref_client.current.value == "")) {
            clientIdValue = null;
        } else {
            clientIdValue = ref_client.current ? parseInt(ref_client.current.value, 10) : null;
        }

        if (ref_user.current && (ref_user.current.value == null || ref_user.current.value == "")) {
            userIdValue = null;
        } else {
            userIdValue = ref_user.current ? parseInt(ref_user.current.value, 10) : null;
        }

        if (ref_interval.current && (ref_interval.current.value == null || ref_interval.current.value == "")) {
            intervalValue = null;
        } else {
            intervalValue = ref_interval.current ? parseInt(ref_interval.current.value, 10) : null;
        }

        const dev: DeviceData = {
            dev_id: 0,
            dev_name: ref_devName.current.value.trim(),
            client_id: clientIdValue,
            u_id: userIdValue,
            interval: intervalValue,
            lfourid: parseInt(ref_lfourid.current.value.trim()),
            servicetag: ref_svcTag.current.value.trim(),
            brokerhost: ref_brkHost.current.value.trim(),
            topic: ref_topic.current.value.trim(),
            profile_no: parseInt(ref_profileNo.current.value.trim()),
            payload_id: parseInt(ref_payloadid.current.value.trim()),
            remark: ref_remk.current.value.trim(),
            enable: isEnabled
        };

        const existingDev = allDevicesData.find(d => d.dev_name === dev.dev_name);
        const existingLfourid = allDevicesData.find(d => d.lfourid === dev.lfourid);
        if (existingDev) {
            emsg += '同じ端末名が既に存在します。\n'
        }
        if (existingLfourid) {
            emsg += '同じlfouridが既に存在します。\n'
        }
        if (dev.dev_name.length < 1) {
            emsg += '新規追加時、端末名は必須です。\n';
        }
        if (isNaN(dev.lfourid)) {
            emsg += '新規追加時、lfouridは必須です。\n';
        }
        if (isNaN(dev.profile_no)) {
            emsg += '新規追加時、送信プロファイルNoは必須です。\n';
        }
        if (isNaN(dev.payload_id)) {
            emsg += '新規追加時、ペイロード名は必須です。\n';
        }

        if (emsg != '') {
            alert(emsg);
            return;
        }

        if (window.confirm('新規端末を追加します。\nよろしいですか。')) {
            let allDevices = await addNewDevice(dev);
            if (allDevices !== undefined && allDevices !== null) {
                if (isClientAdmin && cIDSess) {
                    allDevices = allDevices.filter((d: any) => d.client_id == parseInt(cIDSess))
                }
                setAllDevicesData(allDevices);
                handleClearClick()
            }
        }
    }

    //------------Update Device-----------------
    function handleUpdateClick(dev: DeviceData) {
        if (isClientAdmin && cIDSess) {
            const data = fullUserData.filter(user => user.client_id == parseInt(cIDSess));
            setSelectedUser(data);
        } else {
            const data = fullUserData.filter(user => user.client_id === dev.client_id);
            setSelectedUser(data);
        }
        setSelectedDevice(dev);
        setIsEnabled(dev.enable);
        setShowModal(true);
    }

    async function handleUpdateDevice(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
        let emsg = '';
        setShowModal(false);
        let clientIdValue;
        let userIdValue;
        let intervalValue;

        if (ref_updateClient.current && (ref_updateClient.current.value == null || ref_updateClient.current.value == "")) {
            clientIdValue = null;
        } else {
            clientIdValue = ref_updateClient.current ? parseInt(ref_updateClient.current.value, 10) : null;
        }

        if (ref_updateUser.current && (ref_updateUser.current.value == null || ref_updateUser.current.value == "")) {
            userIdValue = null;
        } else {
            userIdValue = ref_updateUser.current ? parseInt(ref_updateUser.current.value, 10) : null;
        }

        if (ref_updateInterval.current && (ref_updateInterval.current.value == null || ref_updateInterval.current.value == "")) {
            intervalValue = null;
        } else {
            intervalValue = ref_updateInterval.current ? parseInt(ref_updateInterval.current.value, 10) : null;
        }

        const updateDev: Partial<DeviceData> = { ...selectedDevice };
        updateDev.dev_name = ref_updateDevName.current.value.trim();
        updateDev.client_id = clientIdValue;
        updateDev.u_id = userIdValue;
        updateDev.interval = intervalValue;
        updateDev.lfourid = parseInt(ref_updateLfourid.current.value.trim());
        updateDev.servicetag = ref_updateSvcTag.current.value.trim();
        updateDev.brokerhost = ref_updateBrkHost.current.value.trim();
        updateDev.topic = ref_updateTopic.current.value.trim();
        updateDev.profile_no = parseInt(ref_updateProfileNo.current.value.trim());
        updateDev.payload_id = parseInt(ref_updatePayloadid.current.value.trim());
        updateDev.remark = ref_updateRemk.current.value.trim();
        updateDev.enable = isEnabled;

        const existingDev = allDevicesData.find(d => d.dev_name === updateDev.dev_name && d.dev_id !== updateDev.dev_id);
        if (existingDev) {
            emsg += '同じ端末名が既に存在します。\n'
        }
        if (updateDev.dev_name.length < 1) {
            emsg += '端末名は必須です。\n';
        }
        if (isNaN(updateDev.lfourid)) {
            emsg += 'lfouridは必須です。\n';
        }
        if (isNaN(updateDev.profile_no)) {
            emsg += '送信プロファイルNoは必須です。\n';
        }
        if (isNaN(updateDev.payload_id)) {
            emsg += 'ペイロード名は必須です。\n';
        }

        if (emsg != '') {
            window.alert(emsg);
            return;
        }
        if (window.confirm('端末を更新します。\nよろしいですか。')) {
            let updateAllDevs = await updateDevice(updateDev);
            if (updateAllDevs !== undefined && updateAllDevs !== null) {
                if (isClientAdmin && cIDSess) {
                    updateAllDevs = updateAllDevs.filter((d: any) => d.client_id == parseInt(cIDSess))
                }
                setAllDevicesData(updateAllDevs);
                handleClearClick();
            }
        }
    }

    //------------Delete Device-----------------
    async function handleDeleteDev(dev_id: number, dev_name: string, lfourid: number) {
        let msg = '端末（ 端末名：' + dev_name + '；lfourid：' + lfourid + ' ）を削除します。\nこの端末の受信データも一緒に削除されますが、よろしいですか。'

        if (window.confirm(msg)) {
            const response = await deleteDevice(dev_id, dev_name, lfourid);
            if (response) {
                const updatedAllDevicesData = allDevicesData.filter(dev => dev.dev_id !== dev_id);
                setAllDevicesData(updatedAllDevicesData);
                if (selectedDevice?.dev_id == dev_id) {
                    handleClearClick();
                }
            }
        }

    }

    function handleUser(clientId: string) {
        let clientIdValue = ref_client.current.value;
        let cId: number;
        if (clientIdValue !== "") {
            cId = parseInt(clientIdValue, 10)
            const data = fullUserData.filter(user => user.client_id == cId);
            setSelectedUser(data)
        } else {
            const data = fullUserData.filter(user => user.client_id == null);
            setSelectedUser(data)
        }
    }

    function handleNumberInput(event: React.ChangeEvent<HTMLInputElement>) {
        const value = event.target.value;
        const isNumeric = /^\d*$/.test(value);

        if (!isNumeric && !hasShownAlert) {
            window.alert("数字以外の文字または、負値(マイナス)・小数点の値は入力不可");
            event.target.value = value.replace(/\D/g, '');
            setHasShownAlert(true);
        } else if (isNumeric) {
            setHasShownAlert(false);
        }
    }

    function handleInputBlur() {
        setHasShownAlert(false);
    }

    return (

        <div className="App">
            <Navbar collapseOnSelect expand="lg" className="navbar">
                <Navbar.Brand href="/eltres/tracking">ElTRES</Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Offcanvas placement="end" style={{ width: "200px" }}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>
                            ElTRES
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Nav className="justify-content-end flex-grow-1 pe-3 nav-tab">
                            <Nav.Link href="/eltres/tracking">現在地</Nav.Link>
                            <Nav.Link href="/eltres/movingHistory">移動履歴</Nav.Link>
                            <Nav.Link href="/eltres/clients" hidden={isClientAdmin}>取引先設定</Nav.Link>
                            <Nav.Link href="/eltres/users">ユーザ設定</Nav.Link>
                            <Nav.Link href="/eltres/devices" style={{ color: '#bbbcbd' }}>端末設定</Nav.Link>
                            <Nav.Link href="/eltres/payloads" hidden={isClientAdmin}>ペイロード設定</Nav.Link>
                            <Nav.Link href="/eltres/usageAmount" hidden={isClientAdmin}>使用量</Nav.Link>
                            <Nav.Link href="/eltres/download">ダウンロード</Nav.Link>
                            <hr />
                            <Nav.Link href="/eltres/changePWD">パスワード変更</Nav.Link>
                            <Nav.Link onClick={() => uId ? Logout(uId) : moveTo('/eltres/login')}>ログアウト</Nav.Link>
                        </Nav>
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
            </Navbar>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <h2>{isClientAdmin ? '端末一覧表' : '新規｜端末'}</h2>
                        {!isClientAdmin && (
                            <form className="row g-3">
                                <div className="col-md-2">
                                    <label className="form-label">端末名 <span style={{ color: 'red', fontSize: 'small' }}>＊必須</span> </label>
                                    <input type="text" className="form-control" ref={ref_devName} />
                                </div>
                                <div className="col-md-2">
                                    <label className="form-label">LfourID <span style={{ color: 'red', fontSize: 'small' }}>＊必須</span> </label>
                                    <input type="text" className="form-control" ref={ref_lfourid} onChange={handleNumberInput} onBlur={handleInputBlur} />
                                </div>
                                <div className="col-md-2">
                                    <label className="form-label">取引先</label>
                                    <select className="form-select" ref={ref_client} defaultValue="" onChange={(e) => handleUser(e.target.value)}>
                                        <option value="" ></option>
                                        {allClientsData.map((client: ClientData) => (
                                            <option key={client.client_id} value={client.client_id} hidden={!client.enable}>{client.name_knj}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-md-2">
                                    <label className="form-label">ユーザ</label>
                                    <select className="form-select" ref={ref_user} defaultValue="">
                                        <option value="" ></option>
                                        {selectedUser.map((user: FullUserData) => (
                                            <option key={user.u_id} value={user.u_id} hidden={!user.enable}>{user.name_knj}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-md-2">
                                    <label className="form-label">ペイロード名 <span style={{ color: 'red', fontSize: 'small' }}>＊必須</span> </label>
                                    <select className="form-select" ref={ref_payloadid} defaultValue="">
                                        <option value="" disabled hidden></option>
                                        {allPayloadsData.map((pld: PayloadData) => (
                                            <option key={pld.payload_id} value={pld.payload_id}>{pld.payload_name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-md-2">
                                    <label className="form-label">Service Tag</label>
                                    <input type="text" className="form-control" ref={ref_svcTag} />
                                </div>
                                <div className="col-md-2">
                                    <label className="form-label">Broker Host</label>
                                    <input type="text" className="form-control" ref={ref_brkHost} />
                                </div>
                                <div className="col-md-2">
                                    <label className="form-label">トピック</label>
                                    <input type="text" className="form-control" ref={ref_topic} />
                                </div>
                                <div className="col-md-2">
                                    <label className="form-label" >送信プロファイルNo <span style={{ color: 'red', fontSize: 'small' }}>＊必須</span> </label>
                                    <input type="text" className="form-control" ref={ref_profileNo} onChange={handleNumberInput} onBlur={handleInputBlur} />
                                </div>
                                <div className="col-md-2">
                                    <label className="form-label" >送信間隔 (単位：分)</label>
                                    <select className="form-select" ref={ref_interval} defaultValue="">
                                        <option value=""></option>
                                        <option value="1" >１分</option>
                                        <option value="3" >３分</option>
                                        <option value="10" >１０分</option>
                                        <option value="30" >３０分</option>
                                        <option value="60" >１時間</option>
                                        <option value="120" >２時間</option>
                                        <option value="360" >６時間</option>
                                        <option value="720" >１２時間</option>
                                        <option value="1440" >２４時間</option>
                                    </select>
                                </div>
                                <div className="col-md-2">
                                    <label className="form-label">備　　考</label>
                                    <textarea className="form-control" style={{ height: "20px" }} ref={ref_remk} />
                                </div>
                                <div className="col-md-2">
                                    <label className="form-label">有　　効</label>
                                    <div className="form-check checkbox-enabled">
                                        <div className="form-check ">
                                            <input className="form-check-input" type="checkbox" name="enabled" defaultChecked onChange={checkEnableValue} style={{ height: "14px" }} />
                                            <label className="form-check-label">(チェックで有効)</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3" style={{ textAlign: "start" }}>
                                    <button type="submit" className="btn btn-success" onClick={(event) => handleAddClick(event)} style={{ marginRight: "10px" }}>端末追加</button>
                                    <button type="submit" className="btn btn-secondary" onClick={handleClearClick}>クリア</button>
                                </div>
                            </form>
                        )}
                    </div>
                </div>
                <div className="card shadow">
                    <div className="card-body p-4" >
                        <div className="table-card">
                            {allDevicesData.length > 0 ? (
                                <table className="table" style={{ textAlign: 'center' }}>
                                    <thead>
                                        <tr>
                                            <th className='widthNo'>No.</th>
                                            <th className='width12'>端末名</th>
                                            <th className='width7'>LfourID</th>
                                            <th className='width10' hidden={isClientAdmin}>取引先</th>
                                            <th className='width7'>ユーザ</th>
                                            <th className='width7' hidden={isClientAdmin}>送信No.</th>
                                            <th className='width5' hidden={isClientAdmin}>PLD名</th>
                                            <th className='widthNo' hidden={isClientAdmin}>間隔</th>
                                            <th className='widthNo'>有効</th>
                                            <th className='width8'>備考</th>
                                            <th className='width10'>更新/削除</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {allDevicesData.map((dev, index) => (
                                            <tr key={dev.dev_id}>
                                                <td>{index + 1}</td>
                                                <td style={{ textAlign: 'left' }}>{dev.dev_name}</td>
                                                <td>{dev.lfourid}</td>
                                                <td hidden={isClientAdmin}>
                                                    {allClientsData.find(client => client.client_id === dev.client_id)?.name_knj || ""}
                                                </td>
                                                <td>
                                                    {fullUserData.find(user => user.u_id === dev.u_id)?.name_knj || ""}
                                                </td>
                                                <td hidden={isClientAdmin}>{dev.profile_no}</td>
                                                <td hidden={isClientAdmin}>{allPayloadsData.find(p => p.payload_id === dev.payload_id)?.payload_name}</td>
                                                <td hidden={isClientAdmin}>{dev.interval}</td>
                                                <td>{dev.enable ? "有効" : "無効"}</td>
                                                <td>{dev.remark}</td>
                                                <td>
                                                    <button className='btn btn-warning' style={{ marginRight: '5px' }} onClick={() => handleUpdateClick(dev)}>更新</button>
                                                    <button className='btn btn-danger' onClick={() => handleDeleteDev(dev.dev_id, dev.dev_name, dev.lfourid)}>削除</button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ) : (
                                <div style={{ color: 'red' }}>装置の情報がありません。</div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={showModal} onHide={() => setShowModal(false)} centered dialogClassName="modal-lg">
                <Modal.Header closeButton>
                    <Modal.Title>編集</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row user-row">
                        <div className="col-md-12">
                            <form className="row g-3">
                                <div className="col-md-3">
                                    <label className="form-label">端末名<span style={{ color: 'red', fontSize: 'small' }}>＊必須</span></label>
                                    <input type="text" className="form-control" ref={ref_updateDevName} defaultValue={selectedDevice?.dev_name} />
                                </div>
                                <div className="col-md-3">
                                    <label className="form-label">LfourID<span style={{ color: 'red', fontSize: 'small' }}>{isClientAdmin ? '' : '＊必須'}</span></label>
                                    <input type="text" className="form-control" ref={ref_updateLfourid} defaultValue={selectedDevice?.lfourid} disabled={isClientAdmin} onChange={handleNumberInput} onBlur={handleInputBlur} />
                                </div>
                                <div className="col-md-3" hidden={isClientAdmin}>
                                    <label className="form-label">取引先</label>
                                    <select className="form-select" ref={ref_updateClient} defaultValue={selectedDevice ? selectedDevice.client_id || "" : ""} onChange={(e) => handleUser(e.target.value)}>
                                        <option value=""></option>
                                        {allClientsData.map((client: ClientData) => (
                                            <option key={client.client_id} value={client.client_id} hidden={!client.enable}>{client.name_knj}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-md-3">
                                    <label className="form-label">ユーザ</label>
                                    <select className="form-select" ref={ref_updateUser} defaultValue={selectedDevice ? selectedDevice.u_id || "" : ""}>
                                        <option value=""></option>
                                        {selectedUser.map((user: FullUserData) => (
                                            <option key={user.u_id} value={user.u_id} hidden={!user.enable}>{user.name_knj}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-md-3" hidden={isClientAdmin}>
                                    <label className="form-label">Service Tag</label>
                                    <input type="text" className="form-control" ref={ref_updateSvcTag} defaultValue={selectedDevice?.servicetag} />
                                </div>
                                <div className="col-md-3" hidden={isClientAdmin}>
                                    <label className="form-label">Broker Host</label>
                                    <input type="ttextel" className="form-control" ref={ref_updateBrkHost} defaultValue={selectedDevice?.brokerhost} />
                                </div>
                                <div className="col-md-3" hidden={isClientAdmin}>
                                    <label className="form-label">トピック</label>
                                    <input type="text" className="form-control" ref={ref_updateTopic} defaultValue={selectedDevice?.topic} />
                                </div>
                                <div className="col-md-3" hidden={isClientAdmin}>
                                    <label className="form-label" >送信プロファイルNo<span style={{ color: 'red', fontSize: 'small' }}>＊必須</span></label>
                                    <input type="text" className="form-control" ref={ref_updateProfileNo} defaultValue={selectedDevice?.profile_no} onChange={handleNumberInput} onBlur={handleInputBlur} />
                                </div>
                                <div className="col-md-3" hidden={isClientAdmin}>
                                    <label className="form-label">ペイロードID<span style={{ color: 'red', fontSize: 'small' }}>＊必須</span></label>
                                    <select className="form-select" ref={ref_updatePayloadid} defaultValue={selectedDevice ? selectedDevice.payload_id || "" : ""}>
                                        <option value="" disabled hidden></option>
                                        {allPayloadsData.map((pld: PayloadData) => (
                                            <option key={pld.payload_id} value={pld.payload_id}>{pld.payload_name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-md-3" hidden={isClientAdmin}>
                                    <label className="form-label" >送信間隔 (単位：分)</label>
                                    <select className="form-select" ref={ref_updateInterval} defaultValue={selectedDevice?.interval?.toString()}>
                                        <option value=""  ></option>
                                        <option value="1" >１分</option>
                                        <option value="3" >３分</option>
                                        <option value="10" >１０分</option>
                                        <option value="30" >３０分</option>
                                        <option value="60" >１時間</option>
                                        <option value="120" >２時間</option>
                                        <option value="360" >６時間</option>
                                        <option value="720" >１２時間</option>
                                        <option value="1440" >２４時間</option>
                                    </select>
                                </div>
                                <div className="col-md-3">
                                    <label className="form-label">有　　効</label>
                                    <div className="form-check checkbox-enabled">
                                        <div className="form-check ">
                                            <input className="form-check-input" type="checkbox" style={{ height: "14px" }} defaultChecked={selectedDevice?.enable} onChange={checkEnableValue} disabled={isClientAdmin} />
                                            <label className="form-check-label">(チェックで有効)</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <label className="form-label">備　　考</label>
                                    <textarea className="form-control" style={{ height: "20px" }} ref={ref_updateRemk} defaultValue={selectedDevice?.remark} />
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={(event) => handleUpdateDevice(event)}>保存</Button>
                    <Button variant="secondary" onClick={() => { setShowModal(false); }}>キャンセル</Button>
                </Modal.Footer>
            </Modal>
        </div >
    );
}

export default AddDevice;