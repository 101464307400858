import "./App.css";
import "leaflet/dist/leaflet.css";
import React, { useEffect, useRef, useState } from "react";
import { Nav, Navbar, Offcanvas, Modal, Button } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";
import { autoLogoutAlert, Logout, moveTo, addNewPayload, updatePayload, deletePayload, getAllPayloadSourcesDate, getPayloadSourceData, addNewPayloadSource, updatePayloadSource, getAllUsersData, getAllPayloadsDate, TimestampToDate, getAllDevicesDate } from './ELTRES_cmnFunc';
import { PayloadData, PayloadSourceData, UserData, FullUserData, DeviceData } from "./ELTRES_cmnDefine";

const initialSourceData: PayloadSourceData = {
    seq_no: 0,
    payload_id: 0,
    src_no: 0,
    position: 0,
    width: 0,
    src_name: '',
    scale: 0,
    range_min: 0,
    range_max: 0,
    unit: '',
    remark: '',
};

const getDefaultName = (index: number): string => {
    switch (index) {
        case 5: return '緯度(Latitude)';
        case 6: return '経度(Longitude)';
        case 7: return 'GNSSステータス';
        case 9: return 'GNSS時刻';
        default: return '';
    }
};

const initializeSources = (): PayloadSourceData[] => {
    return Array(10).fill(null).map((_, index) => ({
        ...initialSourceData,
        src_no: index + 1,
        src_name: getDefaultName(index)
    }));
};

export function AddPayload(): JSX.Element {
    const uId = sessionStorage.getItem('u_id')?.toString();
    const [showModal, setShowModal] = useState(false);
    const [sources, setSources] = useState<PayloadSourceData[]>(initializeSources());
    const ref_pld = useRef<HTMLInputElement>(null!);
    const ref_remk = useRef<HTMLTextAreaElement>(null!);
    const ref_updatePld = useRef<HTMLInputElement>(null!);
    const ref_updateRemk = useRef<HTMLTextAreaElement>(null!);
    const [allPLDsData, setAllPLDsData] = useState<PayloadData[]>([]);
    const [allPLDSRCsData, setAllPLDSRCsData] = useState<PayloadSourceData[]>([]);
    const [allUsersData, setAllUsersData] = useState<UserData[]>([]);
    const [fullUserData, setFullUserData] = useState<FullUserData[]>([]);
    const [selectedPLD, setSelectedPLD] = useState<PayloadData | null>(null);
    const [selectedPLDSRC, setSelectedPLDSRC] = useState<PayloadSourceData[]>([]);
    const [originalPLDSRC, setOriginalPLDSRC] = useState<PayloadSourceData[]>([]);
    const [allDevicesData, setAllDevicesData] = useState<DeviceData[]>([]);

    //------------------renden page--------------------
    useEffect(() => {
        if (allPLDsData.length == 0) {
            getAllPayloadsDate()
                .then((data) => {
                    setAllPLDsData(data);
                })
                .catch((error) => {
                    autoLogoutAlert()
                    console.error('Failed to getAllPayloadsDate. Occured : ' + error);
                })
        }
        if (allPLDSRCsData.length == 0) {
            getAllPayloadSourcesDate()
                .then((data) => {
                    setAllPLDSRCsData(data);
                })
                .catch((error) => {
                    autoLogoutAlert()
                    console.error('Failed to getAllPayloadSourcesDate. Occured : ' + error);
                })
        }
        if (allUsersData.length == 0) {
            getAllUsersData()
                .then((data) => {
                    setAllUsersData(data);
                    // console.log('allUsersData: ' + allUsersData)
                })
                .catch((error) => {
                    autoLogoutAlert()
                    console.error('Failed to getAllUsersDate. Occured : ' + error);
                })
        }
        if (allDevicesData.length == 0) {
            getAllDevicesDate()
                .then((data) => {
                    setAllDevicesData(data);
                })
                .catch((error) => {
                    autoLogoutAlert()
                    console.error('Failed to getAllDevicesDate. Occured : ' + error);
                })
        }
    }, [])

    useEffect(() => {
        try {
            const fetchData = async () => {
                const promises = allUsersData.map(async (user) => {
                    // console.log('user.u_id : '+ user.u_id)
                    const response = await fetch(`/api/v1/user/${user.u_id}`);
                    const userData = await response.json();
                    const fullUserData: FullUserData = {
                        ...user,
                        password: userData.password ? userData.password : "",
                        client_id: userData.client_id ? userData.client_id : null,
                        name_knj: userData.name_knj,
                        name_kana: userData.name_kana,
                        enable: userData.enable,
                        remark: userData.remark,
                    };
                    return fullUserData;
                });

                const fullUserDataArray = await Promise.all(promises);
                setFullUserData(fullUserDataArray);
                // console.log(fullUserDataArray);
            };
            if (allUsersData.length > 0) {
                fetchData();
            }
        } catch (error) {
            autoLogoutAlert();
            console.error("Failed to fetch allUsersData. Occured : " + error)
        }
    }, [allUsersData]);

    //----------Payload Source data----------------
    const handleRowChange = (index: number, key: string, value: string, type: string) => {
        let formattedValue;
        const numberValue = parseFloat(value);
        if (key === 'scale' || key === 'position') {
            const isNumeric = /^\d*$/.test(value);
            if (numberValue < 0 || !Number.isInteger(numberValue) || isNaN(numberValue)) {
                value= value.replace(/\D/g, '');
                return;
            } else {
                formattedValue = parseInt(value);
            }
        }

        if (key === 'position' || key === 'width') {
            if (numberValue < 0 || numberValue > 127){
                alert('「開始bit位置」と「幅」は　0～127までです');
                return;
            } else {
                formattedValue = parseInt(value);
            }
        }

        if (key === 'range_min' || key === 'range_max') {
            formattedValue = parseFloat(value)
        }

        if(type !== 'number') {
            formattedValue = value
        }

        const newSources = [...sources];
        newSources[index] = { ...newSources[index], [key]: formattedValue };
        setSources(newSources);
    };

    function areAllPayloadSourcesValid(sources: PayloadSourceData[]) {
        let errorMessages: string[] = [];
        let hasSrcNameError = false;

        sources.forEach(source => {
            if (source.src_name.trim() == '' && !hasSrcNameError) {
                errorMessages.push('ペイロードソースの全ての必須フィールドを入力してください。\n「データ名称」は必須です。');
                hasSrcNameError = true;
            }
            if (source.range_min > source.range_max) {
                errorMessages.push('「測定範囲(最小)」は「測定範囲(最大)」より小さい値を入力してください。');
            }
        });

        if (errorMessages.length > 0) {
            alert(errorMessages.join('\n'));
            return false;
        }
        return true;
    }


    //------------Clear Form-----------------
    function handleClearClick() {
        try {
            if (ref_pld.current) ref_pld.current.value = '';
            if (ref_remk.current) ref_remk.current.value = '';
            setSources(initializeSources());
        } catch (error) {
            console.error('Failed to handle clearClick. Occured : ' + error);
            autoLogoutAlert();
        }
    }

    //------------Add Payload-----------------
    async function handleAddPayload(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.preventDefault();
        let emsg = '';
        const pld: PayloadData = {
            payload_id: 0, //auto index
            payload_name: ref_pld.current.value.trim(),
            remark: ref_remk.current.value.trim(),
            update_userid: uId ? JSON.parse(uId).u_id : 0,
            update_date: new Date().toISOString()
        }

        const existingPLD = allPLDsData.find(p => p.payload_name === pld.payload_name);
        if (existingPLD) {
            emsg += '同じペイロード名が既に存在します。\n'
        }
        if (pld.payload_name.length < 1) {
            emsg += '新規追加時、ペイロード名は必須です。\n';
        }
        if (emsg != '') {
            alert(emsg);
            return;
        }
        if (!areAllPayloadSourcesValid(sources)) {
            return;
        }

        if (window.confirm('新規ペイロードを追加します。\nよろしいですか。')) {
            const allPLDs = await addNewPayload(pld);
            if (allPLDs !== undefined && allPLDs !== null) {

                for (let index = 0; index < sources.length; index++) {
                    const source = sources[index];
                    const newSource = { ...source, payload_id: allPLDs, src_no: index + 1 };
                    // console.log('newSource', newSource);
                    try {
                        const seq_no = await addNewPayloadSource(newSource);
                    } catch (error) {
                        console.error('Failed to add payload source:', error);
                    }
                }

                // -------after add payload--------
                const allPayloadsData = await getAllPayloadsDate();
                const allPayloadSourcesData = await getAllPayloadSourcesDate();
                if (allPayloadsData !== undefined && allPayloadsData !== null && allPayloadSourcesData !== undefined && allPayloadSourcesData !== null) {
                    setAllPLDsData(allPayloadsData);
                    setAllPLDSRCsData(allPayloadSourcesData)
                    handleClearClick()
                    window.alert('新規ペイロードを追加しました。')
                }
            }
        }
    }

    //------------Update payload-----------------
    function handleUpdateClick(payload: PayloadData) {
        setShowModal(true);
        setSelectedPLD(payload);
        const updateData = allPLDSRCsData.filter(src => src.payload_id == payload.payload_id);
        setSelectedPLDSRC(updateData);
        setOriginalPLDSRC([...updateData]);
        // console.log(originalPLDSRC)
    }

    const handleEditRowChange = (index: number, key: string, value: string, type: string) => {
        let formattedValue;
        const numberValue = parseFloat(value);
        if (key === 'scale' || key === 'position') {
            if (numberValue < 0 || !Number.isInteger(numberValue) || isNaN(numberValue)) {
                return;
            } else {
                formattedValue = parseInt(value);
            }
        }

        if (key === 'position' || key === 'width') {
            if (numberValue < 0 || numberValue > 127){
                alert('「開始bit位置」と「幅」は　0～127までです');
                return;
            } else {
                formattedValue = parseInt(value);
            }
        }

        if (key === 'range_min' || key === 'range_max') {
            formattedValue = parseFloat(value)
        }

        if(type !== 'number') {
            formattedValue = value
        }
        // const formattedValue = type === 'number' ? parseInt(value) : value;
        const newSelectedPLDSRC = [...selectedPLDSRC];
        newSelectedPLDSRC[index] = { ...newSelectedPLDSRC[index], [key]: formattedValue };
        setSelectedPLDSRC(newSelectedPLDSRC);
    };

    function getModifiedSources() {
        return selectedPLDSRC.filter((source, index) => {
            const originalSource = originalPLDSRC[index];
            return (
                originalSource && (
                    source.position !== originalSource.position ||
                    source.width !== originalSource.width ||
                    source.src_name !== originalSource.src_name ||
                    source.scale !== originalSource.scale ||
                    source.range_min !== originalSource.range_min ||
                    source.range_max !== originalSource.range_max ||
                    source.unit !== originalSource.unit ||
                    source.remark !== originalSource.remark
                )
            );
        });
    }

    async function handleUpdatePLD(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
        const modifiedSources = getModifiedSources();
        let emsg = '';
        const pld: Partial<PayloadData> = { ...selectedPLD }
        pld.payload_id = selectedPLD?.payload_id; //auto index
        pld.payload_name = ref_updatePld.current.value.trim();
        pld.update_userid = uId ? JSON.parse(uId).u_id : 0;
        pld.remark = ref_updateRemk.current.value.trim();
        pld.update_date = new Date().toISOString();

        const existingPLD = allPLDsData.find(p => p.payload_name === pld.payload_name && p.payload_id !== pld.payload_id);
        if (existingPLD) {
            emsg += '同じペイロード名が既に存在します。\n'
        }
        if (pld.payload_name.length < 1) {
            emsg += 'ペイロード名は必須です。\n';
        }
        if (emsg != '') {
            alert(emsg);
            return;
        }
        if (!areAllPayloadSourcesValid(modifiedSources)) {
            return;
        }

        if (window.confirm('ペイロードを更新します。\nよろしいですか。')) {
            const allPLDs = await updatePayload(pld);
            if (allPLDs !== undefined && allPLDs !== null) {
                setAllPLDsData(allPLDs);
                window.alert('ペイロードの情報を更新しました。')
            }
        }

        for (const source of modifiedSources) {
            try {
                const status = await updatePayloadSource(source);
                if (status !== 200) {
                    alert(status + 'エラーが発生しました。\nこのページを再読み込みします。もう一度お試しください。')
                }
            } catch (error) {
                console.error('Failed to update payload source:', error);
            }
        }

        const allPLDSRCsData = await getAllPayloadSourcesDate();
        if (allPLDSRCsData !== undefined && allPLDSRCsData !== null) {
            setAllPLDSRCsData(allPLDSRCsData)
            const updatedSelectedPLDSRC = allPLDSRCsData.filter((src: PayloadSourceData) => src.payload_id === selectedPLD?.payload_id);
            setSelectedPLDSRC(updatedSelectedPLDSRC);
            setOriginalPLDSRC([...updatedSelectedPLDSRC]);
            setShowModal(false)
            handleClearClick()
        }
    }
    //------------Delete payload-----------------
    async function handleDeletePLD(payload_id: number, payload_name: string) {
        let msg = 'ペイロード（ ペイロード名：' + payload_name + ' ）を削除します。\n このペイロードの関連付けのペイロードソースも削除します。\nよろしいですか。'

        const Occupied = allDevicesData.findIndex((dev) => {
            return dev.payload_id === payload_id;
        });
        // console.log('Occupied:', Occupied); 

        if (Occupied !== -1) {
            window.alert('ペイロード（ ペイロード名：' + payload_name + ' ）にはまだ装置が関連付けられています。\n 先に装置とこのペイロードの関連付けを削除または解除してください。')
            return;
        }

        if (window.confirm(msg)) {
            const response = await deletePayload(payload_id, payload_name);
            if (response) {
                const updatedPLDsData = allPLDsData.filter(pld => pld.payload_id !== payload_id);
                const updatedPLDSRCsData = allPLDSRCsData.filter(pldsrc => pldsrc.payload_id !== payload_id);
                setAllPLDsData(updatedPLDsData);
                setAllPLDSRCsData(updatedPLDSRCsData);
                if (selectedPLD?.payload_id == payload_id) {
                    handleClearClick();
                }
            }
        }
    }

    return (
        <div className="App">
            <Navbar collapseOnSelect expand="lg" className="navbar">
                <Navbar.Brand href="/eltres/tracking">ElTRES</Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Offcanvas placement="end" style={{ width: "200px" }}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>
                            ElTRES
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Nav className="justify-content-end flex-grow-1 pe-3 nav-tab">
                            <Nav.Link href="/eltres/tracking">現在地</Nav.Link>
                            <Nav.Link href="/eltres/movingHistory">移動履歴</Nav.Link>
                            <Nav.Link href="/eltres/clients">取引先設定</Nav.Link>
                            <Nav.Link href="/eltres/users">ユーザ設定</Nav.Link>
                            <Nav.Link href="/eltres/devices">端末設定</Nav.Link>
                            <Nav.Link href="/eltres/payloads" style={{ color: '#bbbcbd' }}>ペイロード設定</Nav.Link>
                            <Nav.Link href="/eltres/usageAmount">使用量</Nav.Link>
                            <Nav.Link href="/eltres/download">ダウンロード</Nav.Link>
                            <hr />
                            <Nav.Link href="/eltres/changePWD">パスワード変更</Nav.Link>
                            <Nav.Link onClick={() => uId ? Logout(uId) : moveTo('/eltres/login')}>ログアウト</Nav.Link>
                        </Nav>
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
            </Navbar>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <form className="row g-3">
                            <h2>新規｜ペイロード</h2>
                            <div className="col-md-2">
                                <label className="form-label">ペイロード名</label>
                                <input type="text" className="form-control" ref={ref_pld} />
                            </div>
                            <div className="col-md-2">
                                <label className="form-label">備　　考</label>
                                <textarea className="form-control" style={{ height: "20px" }} ref={ref_remk} />
                            </div>
                            <hr />
                            <div className="table-card">
                                <h4>ペイロードソース<span style={{ color: 'red', fontSize: 'small' }}>　＊ 10個必須</span></h4>
                                <table>
                                    <thead>
                                        <tr>
                                            <th className="width4"><label className="form-label">項目番号</label></th>
                                            <th className="width4"><label className="form-label">開始bit位置</label></th>
                                            <th className="width4"><label className="form-label">幅</label></th>
                                            <th className="width12"><label className="form-label">データ名<span style={{ color: 'red', fontSize: 'small' }}>　＊ 必須</span></label></th>
                                            <th className="width4"><label className="form-label">小数点以下</label></th>
                                            <th className="width4"><label className="form-label">測定範囲(最小)</label></th>
                                            <th className="width4"><label className="form-label">測定範囲(最大)</label></th>
                                            <th className="width4"><label className="form-label">単位</label></th>
                                            <th className="width8"><label className="form-label">備考</label></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {sources.map((row, index) => (
                                            <tr key={index}>
                                                <td scope="row"><input type="number" className="form-control" style={{ width: "90%" }} value={index + 1} disabled onChange={(e) => handleRowChange(index, 'src_no', e.target.value, e.target.type)} /></td>
                                                <td><input type="number" className="form-control" style={{ width: "90%", textAlign: "right" }} value={row.position} onChange={(e) => handleRowChange(index, 'position', e.target.value, e.target.type)} min={0} max={127} step={1}/></td>
                                                <td><input type="number" className="form-control" style={{ width: "90%", textAlign: "right" }} value={row.width} onChange={(e) => handleRowChange(index, 'width', e.target.value, e.target.type)} min={0} max={127} step={1}/></td>
                                                <td><textarea className="form-control" style={{ width: "90%" }} value={row.src_name} onChange={(e) => handleRowChange(index, 'src_name', e.target.value, e.target.type)} /></td>
                                                <td><input type="number" className="form-control" style={{ width: "90%", textAlign: "right" }} value={row.scale} onChange={(e) => handleRowChange(index, 'scale', e.target.value, e.target.type)} /></td>
                                                <td><input type="number" className="form-control" style={{ width: "90%", textAlign: "right" }} value={row.range_min} onChange={(e) => handleRowChange(index, 'range_min', e.target.value, e.target.type)} /></td>
                                                <td><input type="number" className="form-control" style={{ width: "90%", textAlign: "right" }} value={row.range_max} onChange={(e) => handleRowChange(index, 'range_max', e.target.value, e.target.type)} /></td>
                                                <td><input type="text" className="form-control" style={{ width: "90%" }} value={row.unit} onChange={(e) => handleRowChange(index, 'unit', e.target.value, e.target.type)} /></td>
                                                <td><textarea className="form-control" style={{ width: "90%" }} value={row.remark} onChange={(e) => handleRowChange(index, 'remark', e.target.value, e.target.type)} /></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <div className="" style={{ textAlign: "end", margin: "10px 22px" }}>
                                    <button type="submit" className="btn btn-success" style={{ marginRight: "10px" }} onClick={(e) => handleAddPayload(e)}>ペイロード追加</button>
                                    <button type="submit" className="btn btn-secondary" onClick={handleClearClick}>クリア</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <hr />
                <div className="card shadow">
                    <div className="card-body p-4" >
                        <div className="table-card">
                            <table className="table" style={{ textAlign: 'center' }}>
                                <thead>
                                    <tr>
                                        <th className='widthNo'>No.</th>
                                        <th className='width15'>ペイロード名</th>
                                        <th className='width15'>備考</th>
                                        <th className='width10'>更新/削除</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allPLDsData.map((pld, index) => (
                                        <tr key={index}>
                                            <th scope="row">{index + 1}</th>
                                            <td>{pld.payload_name}</td>
                                            <td>{pld.remark}</td>
                                            <td>
                                                <button className='btn btn-warning' style={{ marginRight: "5px" }} onClick={() => handleUpdateClick(pld)} >更新</button>
                                                <button className='btn btn-danger' onClick={() => handleDeletePLD(pld.payload_id, pld.payload_name)}>削除</button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-container">
                <Modal show={showModal} onHide={() => setShowModal(false)} centered dialogClassName="modal-xl  modal-dialog-scrollable">
                    <Modal.Header closeButton>
                        <Modal.Title>編集</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row paylod-row">
                            <div className="col-md-12" style={{ padding: '0px' }}>
                                <form className="row g-3">
                                    <div className="col-md-2">
                                        <label className="form-label">ペイロード名</label>
                                        <input type="text" className="form-control" ref={ref_updatePld} defaultValue={selectedPLD?.payload_name} style={{ width: '160px' }} />
                                    </div>
                                    <div className="col-md-2">
                                        <label className="form-label">備　　考</label>
                                        <textarea className="form-control" style={{ height: "20px" }} ref={ref_updateRemk} defaultValue={selectedPLD?.remark} />
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th className='widthNo'>項目番号</th>
                                                    <th className='width4'>開始位置</th>
                                                    <th className='width3'>幅</th>
                                                    <th className='width10'>データ名<span style={{ color: 'red', fontSize: 'small' }}>　＊必須</span></th>
                                                    <th className='width4'>小数点以下</th>
                                                    <th className='width4'>測定-最小</th>
                                                    <th className='width4'>測定-最大</th>
                                                    <th className='width4'>単位</th>
                                                    <th className='width7'>備考</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {selectedPLDSRC.map((row, index) => (
                                                    <tr key={index}>
                                                        <td scope="row"><input type="number" className="form-control" style={{ width: "90%" }} value={index + 1} disabled /></td>
                                                        <td><input type="number" className="form-control" style={{ width: "90%", textAlign: "right" }} value={row.position} onChange={(e) => handleEditRowChange(index, 'position', e.target.value, e.target.type)}  min={0} max={127} step={1}/></td>
                                                        <td><input type="number" className="form-control" style={{ width: "90%", textAlign: "right" }} value={row.width} onChange={(e) => handleEditRowChange(index, 'width', e.target.value, e.target.type)} min={0} max={127} step={1}/></td>
                                                        <td><textarea className="form-control" style={{ width: "90%" }} value={row.src_name} onChange={(e) => handleEditRowChange(index, 'src_name', e.target.value, e.target.type)} /></td>
                                                        <td><input type="number" className="form-control" style={{ width: "90%", textAlign: "right" }} value={row.scale} onChange={(e) => handleEditRowChange(index, 'scale', e.target.value, e.target.type)} /></td>
                                                        <td><input type="number" className="form-control" style={{ width: "90%", textAlign: "right" }} value={row.range_min} onChange={(e) => handleEditRowChange(index, 'range_min', e.target.value, e.target.type)} /></td>
                                                        <td><input type="number" className="form-control" style={{ width: "90%", textAlign: "right" }} value={row.range_max} onChange={(e) => handleEditRowChange(index, 'range_max', e.target.value, e.target.type)} /></td>
                                                        <td><input type="text" className="form-control" style={{ width: "90%" }} value={row.unit} onChange={(e) => handleEditRowChange(index, 'unit', e.target.value, e.target.type)} /></td>
                                                        <td><textarea className="form-control" style={{ width: "90%" }} value={row.remark} onChange={(e) => handleEditRowChange(index, 'remark', e.target.value, e.target.type)} /></td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={(e) => { handleUpdatePLD(e) }}>保存</Button>
                        <Button variant="secondary" onClick={() => setShowModal(false)}>キャンセル</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    );
}

export default AddPayload;