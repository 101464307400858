import "./App.css";
import "leaflet/dist/leaflet.css";
import React, { useEffect, useState, useRef } from "react";
import { Nav, Navbar, Offcanvas, Modal, Button } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";
import { Logout, autoLogoutAlert, getAllClientsDate, getAllDevicesDate, addNewClient, updateClient, deleteClient, DateFormatOnlyDate, getAllUsersData, TimestampToDate, moveTo } from './ELTRES_cmnFunc';
import { ClientData, FullUserData, UserData, DeviceData } from "./ELTRES_cmnDefine";


export function AddClient(): JSX.Element {
    const uId = sessionStorage.getItem('u_id')?.toString();
    const [showModal, setShowModal] = useState(false);
    const ref_knj = useRef<HTMLInputElement>(null!);
    const ref_kana = useRef<HTMLInputElement>(null);
    const ref_principal = useRef<HTMLInputElement>(null);
    const ref_address = useRef<HTMLInputElement>(null);
    const ref_phone = useRef<HTMLInputElement>(null);
    const ref_fax = useRef<HTMLInputElement>(null);
    const ref_mail = useRef<HTMLInputElement>(null);
    const ref_remk = useRef<HTMLTextAreaElement>(null);
    const ref_updateKnj = useRef<HTMLInputElement>(null!);
    const ref_updateKana = useRef<HTMLInputElement>(null);
    const ref_updatePrincipal = useRef<HTMLInputElement>(null);
    const ref_updateAddress = useRef<HTMLInputElement>(null);
    const ref_updatePhone = useRef<HTMLInputElement>(null);
    const ref_updateFax = useRef<HTMLInputElement>(null);
    const ref_updateMail = useRef<HTMLInputElement>(null);
    const ref_updateRemk = useRef<HTMLTextAreaElement>(null);
    const [cont_date, setContDate] = useState<string | null>(null);
    const [eff_date, setEffDate] = useState<string | null>(null);
    const [end_date, setEndDate] = useState<string | null>(null);
    const [update_date, setUpdateDate] = useState<string | null>(null);
    const [isEnabled, setIsEnabled] = useState(true);
    const checkEnableValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsEnabled(event.target.checked);
    };
    const [selectedClient, setSelectedClient] = useState<ClientData | null>(null);
    const [allClientsData, setAllClientsData] = useState<ClientData[]>([]);
    const [allUsersData, setAllUsersData] = useState<UserData[]>([]);
    const [allDevicesData, setAllDevicesData] = useState<DeviceData[]>([]);
    const [fullUserData, setFullUserData] = useState<FullUserData[]>([]);


    //------------------renden page--------------------
    useEffect(() => {
        if (allClientsData.length == 0) {
            getAllClientsDate()
                .then((data) => {
                    setAllClientsData(data);
                    // console.log('ClientsData : '+ allClientsData.length)
                })
                .catch((error) => {
                    autoLogoutAlert()
                    console.error('Failed to getAllClientsDate. Occured : ' + error);
                })
        }

        if (allUsersData.length == 0) {
            getAllUsersData()
                .then((data) => {
                    setAllUsersData(data);
                })
                .catch((error) => {
                    autoLogoutAlert()
                    console.error('Failed to getAllUsersDate. Occured : ' + error);
                })
        }

        if (allDevicesData.length == 0) {
            getAllDevicesDate()
                .then((data) => {
                    setAllDevicesData(data);
                })
                .catch((error) => {
                    autoLogoutAlert()
                    console.error('Failed to getAllDevicesDate. Occured : ' + error);
                })
        }
    }, [])

    useEffect(() => {
        try {
            const fetchData = async () => {
                const promises = allUsersData.map(async (user) => {
                    // console.log('user.u_id : '+ user.u_id)
                    const response = await fetch(`/api/v1/user/${user.u_id}`);
                    const userData = await response.json();
                    const fullUserData: FullUserData = {
                        ...user,
                        password: userData.password ? userData.password : "",
                        client_id: userData.client_id ? userData.client_id : null,
                        name_knj: userData.name_knj,
                        name_kana: userData.name_kana,
                        enable: userData.enable,
                        remark: userData.remark,
                    };
                    return fullUserData;
                });

                const fullUserDataArray = await Promise.all(promises);
                setFullUserData(fullUserDataArray);
                // console.log(fullUserDataArray);
            };
            if (allUsersData.length > 0) {
                fetchData();
            }
        } catch (error) {
            autoLogoutAlert();
            console.error("Failed to fetch allUsersData. Occured : " + error)
        }
    }, [allUsersData]);

    //-------------- String turn into Date -------------
    const handleDateChange = (inputDate: string, setDate: React.Dispatch<React.SetStateAction<string | null>>) => {
        if (!inputDate) {
            setDate(null);
        } else {
            const isoDate = new Date(inputDate).toISOString();
            setDate(isoDate);
            // console.log('inputDate' + inputDate);
            // console.log('isoDate' + isoDate);
        }
    };

    //------------Clear Form-----------------
    function handleClearClick() {
        try {
            if (ref_knj.current) ref_knj.current.value = '';
            if (ref_kana.current) ref_kana.current.value = '';
            if (ref_principal.current) ref_principal.current.value = '';
            if (ref_address.current) ref_address.current.value = '';
            if (ref_phone.current) ref_phone.current.value = '';
            if (ref_fax.current) ref_fax.current.value = '';
            if (ref_mail.current) ref_mail.current.value = '';
            if (ref_remk.current) ref_remk.current.value = '';
            setContDate(null);
            setEffDate(null);
            setEndDate(null);
            setUpdateDate(null);
            setIsEnabled(true);
        } catch (error) {
            console.error('Failed to handle clearClick. Occured : ' + error);
            autoLogoutAlert();
        }
    }

    //------------Add Client-----------------
    async function handleAddClick() {
        let emsg = '';
        const client: ClientData = {
            client_id: 0,
            name_knj: ref_knj.current.value.trim(),
            name_kana: ref_kana.current?.value.trim(),
            principal: ref_principal.current?.value.trim(),
            address: ref_address.current?.value.trim(),
            phone: ref_phone.current?.value.trim(),
            fax: ref_fax.current?.value.trim(),
            mail: ref_mail.current?.value.trim(),
            cont_date: cont_date,
            eff_date: eff_date,
            end_date: end_date,
            update_date: update_date,
            enable: isEnabled,
            remark: ref_remk.current?.value.trim(),
        }

        const existingClient = allClientsData.find(c => c.name_knj === client.name_knj);
        if (existingClient) {
            emsg += '同じ会社名が既に存在します。\n'
        }
        if (client.name_knj.length < 1) {
            emsg += '新規追加時、会社名（漢字）は必須です。\n';
        }


        if (emsg != '') {
            alert(emsg);
            return;
        }

        if (window.confirm('新規取引先を追加します。\nよろしいですか。')) {
            const allClients = await addNewClient(client);
            if (allClients !== undefined && allClients !== null) {
                setAllClientsData(allClients);
                handleClearClick();
            }
        }
    }

    //------------Update Client-----------------
    function handleUpdateClick(client: ClientData) {
        setSelectedClient(client);
        setIsEnabled(client.enable);
        setShowModal(true);
        setContDate(client.cont_date ?? null);
        setEffDate(client.eff_date ?? null);
        setEndDate(client.end_date ?? null);
        setUpdateDate(client.update_date ?? null);
    }


    async function handleUpdateClient() {
        let emsg = '';
        setShowModal(false);
        let updateClientData: Partial<ClientData> = { ...selectedClient };

        updateClientData.name_knj = ref_updateKnj.current.value.trim();
        updateClientData.name_kana = ref_updateKana.current?.value.trim();
        updateClientData.principal = ref_updatePrincipal.current?.value.trim();
        updateClientData.address = ref_updateAddress.current?.value.trim();
        updateClientData.phone = ref_updatePhone.current?.value.trim();
        updateClientData.fax = ref_updateFax.current?.value.trim();
        updateClientData.mail = ref_updateMail.current?.value.trim();
        updateClientData.cont_date = cont_date;
        updateClientData.eff_date = eff_date;
        updateClientData.end_date = end_date;
        updateClientData.update_date = update_date;
        updateClientData.enable = isEnabled;
        updateClientData.remark = ref_updateRemk.current?.value.trim();


        const existingClient = allClientsData.find(c => c.name_knj === updateClientData.name_knj && c.client_id !== updateClientData.client_id);
        const enableUser = fullUserData.find(u => u.client_id == updateClientData.client_id && u.enable);
        if (existingClient) {
            emsg += '同じ会社名が既に存在します。\n'
        }
        if (updateClientData.name_knj.length < 1) {
            emsg += '会社名（漢字）は必須です。\n';
        }
        if ( !updateClientData.enable && enableUser) {
            emsg += '取引先（ 会社名：' + updateClientData.name_knj + ' ）にはまだユーザーが関連付けられています。\n先にユーザーとこの取引先の関連付けを解除または無効にしてください。'
        }
        if (emsg != '') {
            alert(emsg);
            return;
        }

        if (window.confirm('取引先を更新します。\nよろしいですか。')) {
            const updateAllClients = await updateClient(updateClientData);
            if (updateAllClients !== undefined && updateAllClients !== null) {
                handleClearClick();
                setAllClientsData(updateAllClients);
            }
        }
    }

    //------------Delete Client-----------------
    async function handleDeleteClient(client_id: number, name_knj: string) {
        let msg = '取引先（ 会社名：' + name_knj + ' ）を削除します。よろしいですか。';
        const OccupiedUser = fullUserData.findIndex((user) => {
            return user.client_id === client_id;
        });
        const OccupiedDev = allDevicesData.findIndex((dev) => {
            return dev.client_id === client_id;
        });
        // console.log('Occupied:', Occupied); 

        if (OccupiedUser !== -1) {
            window.alert('取引先（ 会社名：' + name_knj + ' ）にはまだユーザーが関連付けられています。先にユーザーとこの取引先の関連付けを削除または解除してください。')
            return;
        }

        if (OccupiedDev !== -1) {
            window.alert('取引先（ 会社名：' + name_knj + ' ）にはまだ装置が関連付けられています。先に装置とこの取引先の関連付けを削除または解除してください。')
            return;
        }

        if (window.confirm(msg)) {
            const response = await deleteClient(client_id, name_knj);
            if (response) {
                const updatedClientsData = allClientsData.filter(client => client.client_id !== client_id);
                setAllClientsData(updatedClientsData);
                if (selectedClient?.client_id == client_id) {
                    handleClearClick();
                }
            }
        }
    }

    return (
        <div className="App">
            <Navbar collapseOnSelect expand="lg" className="navbar">
                <Navbar.Brand href="/eltres/tracking">ElTRES</Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Offcanvas placement="end" style={{ width: "200px" }}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>
                            ElTRES
                        </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Nav className="justify-content-end flex-grow-1 pe-3 nav-tab">
                            <Nav.Link href="/eltres/tracking">現在地</Nav.Link>
                            <Nav.Link href="/eltres/movingHistory">移動履歴</Nav.Link>
                            <Nav.Link href="/eltres/clients" style={{ color: '#bbbcbd' }}>取引先設定</Nav.Link>
                            <Nav.Link href="/eltres/users">ユーザ設定</Nav.Link>
                            <Nav.Link href="/eltres/devices">端末設定</Nav.Link>
                            <Nav.Link href="/eltres/payloads">ペイロード設定</Nav.Link>
                            <Nav.Link href="/eltres/usageAmount">使用量</Nav.Link>
                            <Nav.Link href="/eltres/download">ダウンロード</Nav.Link>
                            <hr />
                            <Nav.Link href="/eltres/changePWD">パスワード変更</Nav.Link>
                            <Nav.Link onClick={() => uId ? Logout(uId) : moveTo('/eltres/login')}>ログアウト</Nav.Link>
                        </Nav>
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
            </Navbar>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <form className="row g-3">
                            <h2>新規｜取引先</h2>
                            <div className="col-md-2">
                                <label className="form-label">会社名　漢字 <span style={{ color: 'red', fontSize: 'small' }}>＊必須</span> </label>
                                <input type="text" className="form-control" ref={ref_knj} />
                            </div>
                            <div className="col-md-2">
                                <label className="form-label">会社名　カナ</label>
                                <input type="text" className="form-control" ref={ref_kana} />
                            </div>
                            <div className="col-md-2">
                                <label className="form-label">担当者</label>
                                <input type="text" className="form-control" ref={ref_principal} />
                            </div>
                            <div className="col-md-2">
                                <label className="form-label">電話</label>
                                <input type="tel" className="form-control" ref={ref_phone} />
                            </div>
                            <div className="col-md-2">
                                <label className="form-label">FAX</label>
                                <input type="tel" className="form-control" ref={ref_fax} />
                            </div>
                            <div className="col-md-2">
                                <label className="form-label" >メール</label>
                                <input type="email" className="form-control" ref={ref_mail} />
                            </div>
                            <div className="col-md-4">
                                <label className="form-label">所在地</label>
                                <input type="text" className="form-control" ref={ref_address} />
                            </div>
                            <div className="col-md-2">
                                <label className="form-label" >契約締結日</label>
                                <input type="date" className="form-control" onChange={(e) => handleDateChange(e.target.value, setContDate)} />
                            </div>
                            <div className="col-md-2">
                                <label className="form-label" >発効日</label>
                                <input type="date" className="form-control" onChange={(e) => handleDateChange(e.target.value, setEffDate)} />
                            </div>
                            <div className="col-md-2">
                                <label className="form-label" >終了日</label>
                                <input type="date" className="form-control" onChange={(e) => handleDateChange(e.target.value, setEndDate)} />
                            </div>
                            <div className="col-md-2">
                                <label className="form-label" >最終更新日</label>
                                <input type="date" className="form-control" onChange={(e) => handleDateChange(e.target.value, setUpdateDate)} />
                            </div>
                            <div className="col-md-2">
                                <label className="form-label">備　　考</label>
                                <textarea className="form-control" style={{ height: "20px" }} ref={ref_remk} />
                            </div>
                            <div className="col-md-2">
                                <label className="form-label">有　　効</label>
                                <div className="form-check checkbox-enabled">
                                    <div className="form-check ">
                                        <input className="form-check-input" type="checkbox" name="enabled" defaultChecked onChange={checkEnableValue} style={{ height: "14px" }} />
                                        <label className="form-check-label">(チェックで有効)</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3" style={{ textAlign: "start", marginTop: "55px" }}>
                                <button type="submit" className="btn btn-success" onClick={handleAddClick} style={{ marginRight: "10px" }}>取引先追加</button>
                                <button type="submit" className="btn btn-secondary" onClick={handleClearClick}>クリア</button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="card shadow">
                    <div className="card-body p-4">
                        <div className="table-card">
                            {allClientsData.length > 0 ? (
                                <table className="table" style={{ textAlign: 'center' }}>
                                    <thead>
                                        <tr>
                                            <th className='widthNo'>No.</th>
                                            <th className='width12'>会社名　漢字</th>
                                            <th className='width8'>担当者</th>
                                            <th className='width8'>電話</th>
                                            <th className='width8'>メール</th>
                                            <th className='width5'>有効</th>
                                            <th className='width8'>備考</th>
                                            <th className='width10'>更新/削除</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {allClientsData.map((client, index) => (
                                            <tr key={client.client_id}>
                                                <th>{index + 1}</th>
                                                <td style={{textAlign: 'left'}}>{client.name_knj}</td>
                                                <td>{client.principal}</td>
                                                <td>{client.phone}</td>
                                                <td>{client.mail}</td>
                                                <td>{client.enable ? "有効" : "無効"}</td>
                                                <td>{client.remark}</td>
                                                <td>
                                                    <button className='btn btn-warning' style={{ marginRight: "5px" }} onClick={() => handleUpdateClick(client)} >更新</button>
                                                    <button className='btn btn-danger' onClick={() => handleDeleteClient(client.client_id, client.name_knj)}>削除</button>
                                                </td>
                                            </tr>

                                        ))}
                                    </tbody>
                                </table>
                            ) : (
                                <div style={{ color: 'red' }}>取引先の情報がありません。</div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={showModal} onHide={() => setShowModal(false)} centered dialogClassName="modal-lg">
                <Modal.Header closeButton>
                    <Modal.Title>編集</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row user-row">
                        <div className="col-md-12">
                            <form className="row g-3">
                                <div className="col-md-3">
                                    <label className="form-label">会社名　漢字<span style={{ color: 'red', fontSize: 'small' }}>＊必須</span></label>
                                    <input type="text" className="form-control" ref={ref_updateKnj} defaultValue={selectedClient?.name_knj} />
                                </div>
                                <div className="col-md-3">
                                    <label className="form-label">会社名　カナ</label>
                                    <input type="text" className="form-control" ref={ref_updateKana} defaultValue={selectedClient?.name_kana} />
                                </div>
                                <div className="col-md-3">
                                    <label className="form-label">担当者</label>
                                    <input type="text" className="form-control" ref={ref_updatePrincipal} defaultValue={selectedClient?.principal} />
                                </div>
                                <div className="col-md-3">
                                    <label className="form-label">電話</label>
                                    <input type="tel" className="form-control" ref={ref_updatePhone} defaultValue={selectedClient?.phone} />
                                </div>
                                <div className="col-md-3">
                                    <label className="form-label">FAX</label>
                                    <input type="tel" className="form-control" ref={ref_updateFax} defaultValue={selectedClient?.fax} />
                                </div>
                                <div className="col-md-3">
                                    <label className="form-label" >メール</label>
                                    <input type="email" className="form-control" ref={ref_updateMail} defaultValue={selectedClient?.mail} />
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label">所在地</label>
                                    <input type="text" className="form-control" ref={ref_updateAddress} defaultValue={selectedClient?.address} />
                                </div>
                                <div className="col-md-3">
                                    <label className="form-label" >契約締結日</label>
                                    <input type="date" className="form-control" defaultValue={TimestampToDate(selectedClient?.cont_date)} onChange={(e) => handleDateChange(e.target.value, setContDate)} />
                                </div>
                                <div className="col-md-3">
                                    <label className="form-label" >発効日</label>
                                    <input type="date" className="form-control" defaultValue={TimestampToDate(selectedClient?.eff_date)} onChange={(e) => handleDateChange(e.target.value, setEffDate)} />
                                </div>
                                <div className="col-md-3">
                                    <label className="form-label" >終了日</label>
                                    <input type="date" className="form-control" defaultValue={TimestampToDate(selectedClient?.end_date)} onChange={(e) => handleDateChange(e.target.value, setEndDate)} />
                                </div>
                                <div className="col-md-3">
                                    <label className="form-label" >最終更新日</label>
                                    <input type="date" className="form-control" defaultValue={TimestampToDate(selectedClient?.update_date)} onChange={(e) => handleDateChange(e.target.value, setUpdateDate)} />
                                </div>
                                <div className="col-md-3">
                                    <label className="form-label">備　　考</label>
                                    <textarea className="form-control" style={{ height: "20px" }} ref={ref_updateRemk} defaultValue={selectedClient?.remark} />
                                </div>
                                <div className="col-md-3">
                                    <label className="form-label">有　　効</label>
                                    <div className="form-check checkbox-enabled">
                                        <div className="form-check ">
                                            <input className="form-check-input" type="checkbox" defaultChecked={selectedClient?.enable} onChange={checkEnableValue} style={{ height: "14px" }} />
                                            <label className="form-check-label">(チェックで有効)</label>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleUpdateClient}>保存</Button>
                    <Button variant="secondary" onClick={() => { setShowModal(false); }}>キャンセル</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default AddClient;